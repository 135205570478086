.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.back-to-top-button {
    background-color: var(--primary500);
    color: var(--light);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    // font-size: 14px;
    font-weight: 400;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition:
        opacity 0.3s,
        visibility 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    padding: 0;

    .icon {
        width: 20px;
        height: auto;
    }
}

.back-to-top-button:hover {
    background-color: var(--secondary-navy-blue);
}