@import "../../../App.scss";

#map {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
}

.map-container {
    width: 100%;
    // height: calc(100vh - 300px);
    height: auto;
}

.mapboxgl-ctrl-bottom-right {
    display: none !important;
}

a.mapboxgl-ctrl-logo {
    display: none !important;
}

.marker {
    /*display: block;*/
    border: none;
    /*border-radius: 50%;*/
    cursor: pointer;
    padding: 0;
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Map_pin_icon.svg/176px-Map_pin_icon.svg.png");
    background-size: cover;
    width: 20px;
    height: 27px;
    /*border-radius: 50%;*/
    /*cursor: pointer;*/
}

.mapboxgl-popup {
    max-width: 200px;
}
.mapboxgl-popup-content {
    background-color: #213345 !important;
    padding: 10px 10px;
    color: #7797ae;
    border-radius: 5px !important;
    font-family: "Ubuntu";

    h3 {
        font-size: 16px;
        font-weight: 400;
        padding-top: 5px;
    }

    p {
        margin-bottom: 0px;
    }

    hr {
        margin: 0.4rem 0 !important;
    }

    .mapbox-content-highlight {
        color: #ffffff;
    }

    .map_marker_tooltip {
        background-color: #213345;
        .title {
            font-size: 13px;
            font-weight: 500;
            color: #ffffff;
        }
        .subtitle {
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
        }
    }
}
// .mapboxgl-popup-tip {
//     border-top-color: #213345 !important;
//     border-bottom-color: #213345 !important;
// }

.mapboxgl-popup-close-button {
    color: #7797ae !important;
    font-size: 14px;
}

@media (max-width: 520px) {
    .map-container {
        width: 100%;
        height: 300px;
    }
}

@media (max-width: 768px) {
    .map-container {
        width: 100%;
        height: 300px;
    }
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: #213345 !important;
    margin-bottom: -3px !important;
    }
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: #213345 !important;
    margin-top: -3px !important;
    }
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #213345 !important;
    }
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #213345 !important;
    }