/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
 */
:root {
  --gray50: #bacad6;
  --gray75: #fafafa;
  --gray100: #f5f5f5;
  --gray200: #eaeaea;
  --gray300: #e1e1e1;
  --gray400: #cacaca;
  --gray500: #b3b3b3;
  --gray600: #8e8e8e;
  --gray700: #6e6e6e;
  --gray800: #4b4b4b;
  --gray900: #2c2c2c;

  --primary400: #4cc3fd;
  --primary500: #11a1fd;
  --primary600: #0c7dd9;
  --primary700: #085db6;

  --primary-hover200: rgba(17, 161, 253, 0.2);

  --secondary-red400: #e96e7a;
  --secondary-red500: #d51f30;
  --secondary-red600: #b51c2a;
  --secondary-red700: #85010d;
  --secondary-red-hover200: #e96e7a;

  --secondary-purple400: #8298fb;
  --secondary-purple500: #5a75f9;
  --secondary-purple600: #4259d4;
  --secondary-purple700: #3247b5;

  --secondary-green400: #3cd795;
  --secondary-green500: #07c180;
  --secondary-green600: #06a57d;
  --secondary-green700: #038673;

  --secondary-yellow400: #ffb866;
  --secondary-yellow500: #ff9931;
  --secondary-yellow600: #db7823;
  --secondary-yellow700: #b45917;
  --secondary-yellow-hover200: #ffb866;

  --secondary-gray400: #cedfe8;
  --secondary-gray500: #a7c1d2;
  --secondary-gray600: #7d9eb5;
  --secondary-gray700: #486f88;

  --secondary-navy-blue: #213345;

  --font-size-xlarge: 36px;
  --font-size-large: 24px;
  --font-size-normal: 16px;
  --font-size-small: 14px;
  --font-size-xsmall: 12px;

  --h1-font-size: 28px;
  --h2-font-size: 24px;
  --h3-font-size: 20px;
  --h4-font-size: 18px;
  --h5-font-size: 16px;
  --h6-font-size: 12px;

  --font-weight700: 700;
  --font-weight500: 500;
  --font-weight400: 400;
  --font-weight300: 300;

  --grid-size-small: 576px;
  --grid-size-medium: 768px;
  --grid-size-large: 992px;
  --grid-size-xlarge: 1140px;

  --msgColor: #ffffff;

  --body-bg: #f8f8f8
    /*#f3f2ef*/
  ;

  --light: #ffffff;
  --dark: #000000;

  --dropdown-options: #292929cc;
  --dropdown-options-focused: #292929;
  --dropdown-bg: #bfe7ff;
}

[data-theme="dark"] {
  /*
    --gray50: #080808;
    --gray75: #1a1a1a;
    --gray100: #1e1e1e;
    --gray200: #2c2c2c;
    --gray300: #393939;
    --gray400: #494949;
    --gray500: #5c5c5c;
    --gray600: #7c7c7c;
    --gray700: #6e6e6e;
    --gray800: #a2a2a2;
    --gray900: #efefef;
    */
  --gray50: #252525;
  --gray75: #2f2f2f;
  --gray100: #323232;
  --gray200: #3e3e3e;
  --gray300: #4a4a4a;
  --gray400: #5a5a5a;
  --gray500: #6e6e6e;
  --gray600: #909090;
  --gray700: #b9b9b9;
  --gray800: #e3e3e3;
  --gray900: #ffffff;
}

:export {
  gray50: var(--gray50);
  gray75: var(--gray75);
  gray100: var(--gray100);
  gray200: var(--gray200);
  gray300: var(--gray300);
  gray400: var(--gray400);
  gray500: var(--gray500);
  gray600: var(--gray600);
  gray700: var(--gray700);
  gray800: var(--gray800);
  gray900: var(--gray900);

  primary400: var(--primary400);
  primary500: var(--primary500);
  primary600: var(--primary600);
  primary700: var(--primary700);

  secondary-red400: var(--secondary-red400);
  secondary-red500: var(--secondary-red500);
  secondary-red600: var(--secondary-red600);
  secondary-red700: var(--secondary-red700);

  secondary-purple400: var(--secondary-purple400);
  secondary-purple500: var(--secondary-purple500);
  secondary-purple600: var(--secondary-purple600);
  secondary-purple700: var(--secondary-purple700);

  secondary-green400: var(--secondary-green400);
  secondary-green500: var(--secondary-green500);
  secondary-green600: var(--secondary-green600);
  secondary-green700: var(--secondary-green700);

  secondary-yellow400: var(--secondary-yellow400);
  secondary-yellow500: var(--secondary-yellow500);
  secondary-yellow600: var(--secondary-yellow600);
  secondary-yellow700: var(--secondary-yellow700);

  secondary-gray400: var(--secondary-gray400);
  secondary-gray500: var(--secondary-gray500);
  secondary-gray600: var(--secondary-gray600);
  secondary-gray700: var(--secondary-gray700);

  light: var(--light);
  dark: var(--dark);
  title-color: var(--secondary-navy-blue);

  dropdownOptions: var(--dropdown-options);
  dropdownOptionFocused: var(--dropdown-options-focused);
  dropdownBg: var(--dropdown-bg);
}

#root {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  // background-color: yellow;
}

body {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.8;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 70px);
  // display: flex;
  // flex-direction: column;
  // flex: 1;
  background-color: var(--body-bg);
  // background-color: red;
  font-size: var(--font-size-normal);
  // overflow-y: hidden;
  // @media (max-width: 768px) {
  //   overflow-y: auto;
  // }
}

// MULTI SELECT DROPDOWN WRAP CSS
.css-1hwfws3 {
  flex-wrap: nowrap !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 30px;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--font-weight700);
  margin-bottom: 0;
  color: var(--secondary-navy-blue);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight500);
  margin-bottom: 0;
  color: var(--secondary-navy-blue);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight500);
  margin-bottom: 0;
  color: var(--secondary-navy-blue);
}

h4 {
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight400);
  margin-bottom: 0;
  color: var(--secondary-navy-blue);
}

h5 {
  font-size: var(--h5-font-size);
  font-weight: var(--font-weight400);
  margin-bottom: 0;
  color: var(--secondary-navy-blue);
}

h6 {
  font-size: var(--h6-font-size);
  font-weight: var(--font-weight400);
  margin-bottom: 0;
  color: var(--secondary-navy-blue);
}

.border {
  border: 1px solid var(--gray50);
  border-radius: 7px;
}

.bold {
  font-weight: var(--font-weight700) !important;
}

.visibility_hidden {
  visibility: hidden !important;
}

.m_t_5 {
  margin-top: 5px;
}

.p_10 {
  padding: 10px;
}

.p_20 {
  padding: 20px;
}

.p_t_0 {
  padding-top: 0px !important;
}

.p_b_0 {
  padding-bottom: 0px !important;
}

.grid_padding {
  padding: 0 20px;
}

.info_text {
  color: var(--gray500);
}

.warning_text {
  color: var(--secondary-yellow500);
}

.error_text {
  color: var(--secondary-red500);
  line-height: 1;
}

button:focus-visible,
button:focus-within,
button:focus {
  border: none;
  outline: none;
}

button,
input,
select {
  font-family: "Ubuntu", sans-serif;
}

a {
  text-decoration: none;
}

a,
a:active,
a:visited {
  color: var(--primary500) !important;
}

a:hover {
  color: var(--secondary-navy-blue);
}

img {
  width: 100%;
  height: auto;
}

input,
textarea {
  width: 100%;
  color: var(--gray900);
  background: var(--light);
  padding: 4px 8px;
  font-size: var(--font-size-normal);
  line-height: 1.8;
  border: 1px solid var(--secondary-gray500);
  border-radius: 7px;
  outline: none;
  resize: none;
  flex: 1;

  &:hover {
    border-color: var(--primary500);
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    background-color: rgba(17, 161, 253, 0.1);
    border-color: var(--primary500);
  }
}

.form_group {
  .invalid {
    border-color: var(--secondary-red500);
    background: rgba(213, 31, 48, 0.1);
    color: var(--secondary-red500);
  }
}

input[type="checkbox"]+label {
  margin-left: 10px;
}

.checkbox_input {
  background-color: red;
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
}

label {
  flex: 1;
  margin-right: 20px;
}

label:empty {
  flex: 0;
}

dt {
  font-weight: 600;
  margin: 0 0 5px 0;
}

dd {
  margin: 0 0 30px 0;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

svg {
  shape-rendering: geometricPrecision;
}

#__next {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}

#__modals {
  z-index: 10;
}

.container {
  padding: 0;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.row>.col,
.row>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.center {
  text-align: center;
}

.w_100 {
  width: 100%;
}

.h_100 {
  height: 100%;
}

.h_auto {
  height: auto !important;
}

.overflow_auto {
  overflow: auto;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--gray500);
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: var(--gray50);
}

.detailed_sidebar_scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */

.detailed_sidebar_scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */

.detailed_sidebar_scrollbar::-webkit-scrollbar-thumb {
  background: var(--gray500);
  border-radius: 10px;
  border: 2px solid #1a2937;
}

// TITLES
.section_card_title {
  font-size: 20px;
  color: var(--secondary-navy-blue);
  line-height: 1.2;
  font-weight: var(--font-weight700);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 3;
  width: 100%;

  // z-index: 9999 !important;
  .hover_badge_wrapper {
    margin-left: 10px;
    position: relative;

    .badge_hover {
      min-width: 150px !important;
      width: 100% !important;
      max-width: 300px !important;
      top: 30px !important;
      left: 0 !important;
      right: 0 !important;
      z-index: 9999 !important;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.section_header {
  padding: 0px;

  .section_title {
    font-size: 20px;
    color: var(--secondary-navy-blue);
    line-height: 1.2;
    font-weight: var(--font-weight700);
    text-transform: capitalize;
  }

  @media (max-width: 576px) {
    padding: 10px;
  }
}

.section_header.slider_header {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
}

// GRID
.grid {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  @media (max-width: 576px) {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

.grid_margin_bottom {
  margin-bottom: 20px;

  @media (max-width: 576px) {
    margin-bottom: 10px;
  }
}

.grid_margin_top {
  margin-top: 20px;

  @media (max-width: 576px) {
    margin-top: 10px;
  }
}

.grid_margin_right {
  margin-right: 20px;

  @media (max-width: 576px) {
    margin-right: 10px;
  }
}

.grid_margin_left {
  margin-left: 20px;

  @media (max-width: 576px) {
    margin-left: 10px;
  }
}

.grid_cols_1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid_cols_2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid_cols_3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid_cols_4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid_cols_5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid_cols_6 {
  grid-template-columns: repeat(6, 1fr);
}

.col_auto {
  grid-column: auto;
}

.col_span_1 {
  grid-column: span 1 / span 1;
}

.col_span_2 {
  grid-column: span 2 / span 2;
}

.col_span_3 {
  grid-column: span 3 / span 3;
}

.col_span_4 {
  grid-column: span 4 / span 4;
}

.col_span_5 {
  grid-column: span 5 / span 5;
}

.col_span_6 {
  grid-column: span 6 / span 6;
}

.form_grid .input_holder {
  margin-bottom: 0;
}

.form_grid+.form_action_container {
  padding-top: 20px;
}

.cms_grid {
  grid-template-columns: auto 240px;
  grid-column-gap: 40px;
}

.grid_fluid {
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
}

.section_grid {
  padding: 0 20px;
}

.col_start_2 {
  grid-column-start: 2;

  @media (max-width: 768px) {
    grid-column-start: 1;
  }
}

.col_start_3 {
  grid-column-start: 3;

  @media (max-width: 768px) {
    grid-column-start: 1;
  }
}

.col_start_4 {
  grid-column-start: 4;

  @media (max-width: 768px) {
    grid-column-start: 1;
  }
}

.grid.grid_center {
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
}

@media (max-width: 768px) {

  .grid.grid_cols_2,
  .grid.grid_cols_3,
  .grid.grid_cols_4,
  .grid.grid_cols_5,
  .grid.grid_cols_6 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid.section_grid {
    padding: 0 20px;
  }
}

@media (max-width: 992px) {

  .grid.grid_cols_4,
  .grid.grid_cols_5,
  .grid.grid_cols_6 {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid .col_span_2,
  .grid .col_span_2,
  .grid .col_span_3,
  .grid .col_span_4,
  .grid .col_span_5 {
    // grid-column: initial;
    grid-template-columns: repeat(1, 1fr);
  }
}

// BUTTONS
.btn_wrapper {
  width: auto;
  padding: 0px;
}

.btn_wrapper.full_width {
  width: 100%;
}

.btn_wrapper.btn_group_wrapper {
  width: 100%;
  padding: 10px;
}

.btn_wrapper.width_fit_content {
  width: fit-content;
}

.button_badge {
  font-size: 14px;
  border-radius: 5px;
  // border: 1px solid;
  padding: 5px 10px;
  margin: 3px;
  font-weight: var(--font-weight400);
  color: var(--secondary-gray700);
  // background-color: var(--secondary-gray400);
  background: linear-gradient(90deg, #486f88 50%, #cedfe8 0);
  background-size: 200% 100%;
  background-position: 100% 100%;
  transition: all 1s ease-out;
  border: none;
  // border-color: var(--secondary-gray400);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: auto;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;

  &:hover {
    cursor: pointer;
  }
}

.button_badge.active {
  // background-color: var(--secondary-gray700);
  // border-color: var(--secondary-gray700);
  background-position: 0 100%;
  color: var(--light);
}

.button_badge.greenBadgeBg {
  background: rgba(209, 231, 221, 0.5);
  color: #0f5132;
  font-size: 15px;
  padding: 20px 20px;
  border: 1px solid #badbcc;
}

.button_badge.redBadgeBg {
  background: #f8d7da;
  color: #842029;
  border: 1px solid #f5c2c7;
  font-size: 15px;
  padding: 20px 20px;

  a {
    display: inline-block;
    color: #842029 !important;
    text-decoration: underline;
    font-weight: 700;
  }
}

.theme_button {
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid;
  padding: 0 20px;
  // padding: 5px 20px;
  // margin: 10px;
  font-weight: var(--font-weight400);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 39px;
  width: 100%;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;

  &:disabled,
  &:focus:disabled,
  &:hover:disabled,
  &:active:disabled {
    background: var(--gray500);
    border-color: var(--gray500);
    color: var(--light);
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus-visible,
  &:focus-within {
    border: 1px solid var(--primary500);
  }

  .icon_wrapper {
    height: 30px;

    .btn_icon {
      height: 100%;
      width: auto;
      stroke: var(--light);
    }

    .btn_title_icon {
      height: 100%;
      width: auto;
      stroke: var(--light);
      margin-right: 8px;
    }
  }
}

.theme_button.width_fit_content {
  width: fit-content;
}

.primary_btn {
  background-color: var(--primary500);
  border-color: var(--primary500);
  color: var(--light);

  &:hover {
    background-color: var(--secondary-navy-blue);
    border-color: var(--secondary-navy-blue);
    color: var(--light);
  }
}

.secondary_btn {
  background-color: transparent;
  border-color: var(--primary500);
  color: var(--primary500);

  .btn_title_icon,
  .btn_icon {
    stroke: var(--primary500) !important;
  }

  &:hover {
    background-color: var(--secondary-navy-blue);
    border-color: var(--secondary-navy-blue);
    color: var(--light);

    .btn_title_icon,
    .btn_icon {
      stroke: var(--light) !important;
    }
  }
}

.tertiary_btn {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary500);

  &:hover {
    background-color: var(--primary-hover200);
    border-color: transparent;
    color: var(--primary500);
  }
}

.warning_btn {
  background-color: var(--secondary-yellow400);
  border-color: var(--secondary-yellow400);
  color: var(--light);

  &:hover {
    background-color: var(--secondary-yellow500);
    border-color: var(--secondary-yellow500);
    color: var(--light);
  }
}

.secondary_warning_btn {
  background-color: transparent;
  border-color: var(--secondary-yellow500);
  color: var(--secondary-yellow500);

  &:hover {
    background-color: var(--secondary-yellow500);
    border-color: var(--secondary-yellow500);
    color: var(--light);
  }
}

.Tertiary_warning_btn {
  background-color: transparent;
  border-color: transparent;
  color: var(--secondary-yellow500);

  &:hover {
    background-color: var(--secondary-yellow-hover200);
    border-color: var(--secondary-yellow-hover200);
    color: var(--light);
  }
}

.danger_btn {
  background-color: var(--secondary-red400);
  border-color: var(--secondary-red400);
  color: var(--light);

  &:hover {
    background-color: var(--secondary-red500);
    border-color: var(--secondary-red500);
    color: var(--light);
  }
}

.secondary_danger_btn {
  background-color: transparent;
  border-color: var(--secondary-red500);
  color: var(--secondary-red500);

  &:hover {
    background-color: var(--secondary-red500);
    border-color: var(--secondary-red500);
    color: var(--light);
  }
}

.Tertiary_danger_btn {
  background-color: transparent;
  border-color: transparent;
  color: var(--secondary-red500);

  &:hover {
    background-color: var(--secondary-red-hover200);
    border-color: transparent;
    color: var(--light);
  }
}

.disabled_btn {
  // background: var(--gray500);
  // border-color: var(--gray500);
  border: none;
  color: var(--gray500) !important;
  cursor: not-allowed;
  // opacity: 0.5;
  width: auto;

  &:hover {
    cursor: not-allowed;
  }
}

// SLIDER
.slider_wrapper_section {
  margin-bottom: 20px;
}

.slider_wrapper_section.tab_card_slider {
  margin-bottom: 0;
}

.navigation {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid;
  border-color: var(--primary500);
  color: var(--primary500);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: var(--secondary-navy-blue);
    border-color: var(--secondary-navy-blue);
    color: var(--light);

    .slider_arrow {
      stroke: var(--light);
    }
  }

  &:focus-within,
  &:focus-visible {
    background-color: transparent;
    border: 1px solid;
    border-color: var(--primary500);
    color: var(--primary500);

    .slider_arrow {
      stroke: var(--primary500);
    }
  }

  .slider_arrow {
    stroke: var(--primary500);
  }
}

.navigation.prev {
  margin-right: 10px;
}

.navigation.disabled {
  background: var(--gray500);
  border-color: var(--gray500);
  color: var(--light);
  cursor: not-allowed;
  opacity: 0.5;

  .slider_arrow {
    stroke: var(--light);
  }
}

// CARD
.card_wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  // align-items: flex-start;
  .slider_wrapper {
    width: 100%;
    position: relative;

    .swiper-wrapper {
      .swiper-slide {
        width: auto;
      }
    }
  }

  .card {
    background-color: var(--light);
    border-radius: 7px;
    padding: 0;
    margin: 0;
    // width: 220px;
    width: 190px;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 4px solid transparent;
    box-shadow: 0 3px 10px rgba(215, 170, 226, 0.23);

    // min-height: 250px;
    // margin-right: 20px;
    .card_header {
      position: relative;
      // height: 60px;
      height: auto;
      box-shadow: 0 10px 10px 0px rgba(242, 242, 242, 0.5);
      padding: 10px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .card_title_wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .card_icon {
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 7px;

          img {
            width: 35px;
            height: 35px;
            border-radius: 7px;

            &::before {
              content: " ";
              display: block;
              position: absolute;
              height: 30px;
              width: 30px;
              background-image: url("../assets/images/default/postImg.png");
            }
          }
        }

        .card_header_title {
          font-size: 0.875rem;
          // color: var(--secondary-gray500);
          word-wrap: break-all;
          align-items: center;
          color: #7d9eb5;
          display: flex;
          font-weight: 700;
          font-weight: var(--font-weight700);
          justify-content: flex-start;
          line-height: 1;
          min-height: 35px;
          text-transform: capitalize;
        }
      }

      .hover_badge_wrapper {
        margin-left: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .card_body_wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .card_body {
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;

        .card_body_row {
          padding: 20px;

          .value_row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            white-space: nowrap;
            margin-bottom: 5px;
          }

          .title_row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }

          .curr_value {
            font-size: 1.375rem;
            font-weight: var(--font-weight500);
            color: var(--secondary-navy-blue);
            line-height: 1.2;
            text-align: left;
            margin-right: 5px;
            position: relative;
          }

          .prev_value {
            font-size: 0.875rem;
            font-weight: var(--font-weight500);
            color: var(--secondary-gray500);
            line-height: 1.4;
            text-align: left;
            position: relative;
          }

          .value_title {
            font-size: 0.875rem;
            font-weight: var(--font-weight400);
            color: var(--secondary-gray600);
            line-height: 1.4;
            text-align: left;
            margin-top: 2px;
            white-space: nowrap;
          }
        }

        & .current_prev_row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .growth_row {
          margin-top: 5px;
        }
      }
    }

    .card_body_wrapper.post_compare_card {
      background-color: red;
    }

    .card_progressbar {
      padding: 10px 20px;
      width: -webkit-fill-available;

      .progressbar_title {
        font-size: 12px;
        font-weight: var(--font-weight400);
        color: var(--secondary-gray500);
        line-height: 1;
      }

      .progressbar_wrapper {
        .wrapper {
          border: 3px solid blue;
        }

        .container {
          background-color: pink;
        }

        .barCompleted {
          background-color: lightblue;
          width: 80%;
        }

        .label {
          font-size: 12px;
          color: green;
        }
      }
    }

    .card_progressbar+.card_body {
      .card_body_row {
        padding: 10px 20px;

        .growth_row {
          padding-top: 10px;
        }
      }
    }

    .card_footer {
      width: 100%;
      height: 100%;

      .metrics_card_chart_wrapper {
        width: 100%;
        height: 100px;
        padding: 0px;

        .metrics_card_chart {
          width: 100%;
          height: 100%;
        }
      }

      .growth_row {
        width: auto;
        padding: 10px 20px;
      }
    }

    .card_new_footer {
      // background-color: yellow;
      width: calc(100% - 30px);
      height: 100px;
      margin-top: -35px;
      padding-bottom: 20px;

      .metrics_card_chart_new_wrapper {
        // background-color: red;
        width: 100%;
        height: 150px;

        .metrics_card_chart {
          // background-color: blue;
          // margin-left: -5px;
          width: 100%;
          height: 100%;
          margin: 0 10px;
        }
      }
    }
  }

  .card.tabCard {
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .card.tabCard.active {
    border-color: var(--primary500);

    .active_arrow {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--primary500);
      position: absolute;
      bottom: 0;
      right: calc(50% - 10px);
      z-index: 999999 !important;
      margin: auto;
    }
  }

  .card.postCard {
    background-color: transparent;
    width: auto;
    border: 1px solid var(--gray300);
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    .card_header {
      box-shadow: none;
      background-color: transparent;
      height: auto;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .time_wrapper {
        font-size: var(--h6-font-size);
        color: var(--secondary-navy-blue);
        font-weight: var(--font-weight400);
        // padding-left: 15px;
      }

      .checkbox_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .checkbox_input {
          width: 15px;
          height: 15px;
        }
      }
    }

    .card_body {
      background-color: transparent;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      .left_Section {
        width: 200px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .post_img_section {
          width: 150px;
          height: auto;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          padding: 10px;

          .img_wrapper {
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
            border-radius: 7px;
            transition: all 500ms ease-in-out;

            &:hover {
              img:not(.icon) {
                transform: scale(1.15, 1.15);
              }
            }

            img {
              transform: scale(1, 1);
              width: 100%;
              height: 100%;
              border-radius: 0;
              transition: all 500ms ease-in-out;

              &::before {
                content: " ";
                display: block;
                position: absolute;
                height: auto;
                width: 100%;
                background-image: url("../assets/images/default/postImg.png");
              }
            }

            .overlay_wrapper {
              width: 100%;
              height: 70px;
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(180deg, transparent, #000);
              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              flex-direction: row;

              .platform_thumbnail_wrapper {
                position: absolute;
                bottom: 5px;
                left: 5px;

                .iconWrapper {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .icon {
                    height: 20px;
                    width: auto;
                  }
                }
              }

              .view_more_platform_thumbnail_wrapper {
                bottom: 10px !important;
                left: 10px !important;

                .iconWrapper {
                  .icon {
                    height: 50px !important;
                    width: auto;
                  }
                }
              }
            }
          }
        }

        .caption_wrapper {
          font-size: var(--font-size-xsmall);
          color: var(--gray500);
          margin-bottom: 10px;
          text-align: center;
        }

        .button_wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            padding: 0;
          }
        }
      }

      .center_section {
        .caption_wrapper {
          font-size: var(--font-size-xsmall);
          color: var(--gray500);
          margin-bottom: 10px;
          text-align: left;
        }
      }

      .right_Section {
        width: calc(100% - 200px);

        .post_details_section {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          background-color: transparent;

          .section_card_table_wrapper {
            width: 100%;

            .table_wrapper {
              padding-left: 20px;
              padding: 0px;

              table {
                td {
                  padding: 15px 15px 15px 0px;
                }

                tr {
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }

        .button_wrapper {
          .theme_button {
            width: 50%;
            margin-left: auto;
            height: 35px;
          }
        }
      }
    }

    .card_footer {
      padding-top: 10px;
      font-size: var(--font-size-normal);
      color: var(--secondary-navy-blue);
    }
  }

  .card.postCard.single {
    .card_header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card_body {
      width: 100%;

      .left_Section {
        width: 100%;

        .post_img_section {
          width: 100%;
        }
      }
    }
  }

  .card.postCard.postCard_single_post {
    width: auto;
    margin-bottom: 20px;
    padding: 0px;
    border: none;

    .card_body {
      justify-content: space-between;
      align-items: flex-start;

      .left_Section {
        // width: 100%;
        width: 30%;
        max-width: 350px;
        height: 100%;
        max-height: 350px;

        .post_img_section {
          width: 100%;
          height: 100%;
          padding: 0px;

          .img_wrapper img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .center_section {
        padding: 20px;
        padding-left: 0px;
        width: 40%;

        // width: calc(50% - 175px);
        max-width: 100%;
        height: 100%;

        .time_wrapper {
          color: var(--secondary-gray700);
          font-weight: 600;
        }

        .caption_wrapper {
          color: var(--secondary-gray600);
        }
      }

      .card_border_right {
        &::after {
          right: 5px;
        }
      }

      .right_Section {
        // width: calc(50% - 175px);
        width: 30%;
        max-width: 100%;
        height: 100%;
      }
    }
  }

  // TWITTER TOP MENTIONS CARD
  .card.topMentionsCard {
    background-color: var(--light) !important;
    width: 100% !important;

    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 20px 10px;

      .profileImgWrapper {
        width: 80px;
        height: 80px;

        .profileImg {
          width: auto;
          height: 100%;
          border: 1px solid var(--secondary-gray500);
          border-radius: 100%;
        }
      }

      .titleWrapper {
        padding-left: 10px;

        .name {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: var(--font-size-normal);
          font-weight: var(--font-weight700);
          color: var(--secondary-navy-blue);

          .verifiedIconWrapper {
            width: 20px;
            height: 20px;
            margin-left: 5px;

            .verifiedIcon {
              width: 100%;
              height: auto;
            }
          }
        }

        .username {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: var(--font-size-small);
          font-weight: var(--font-weight500);
          color: var(--secondary-gray500);
        }
      }
    }

    .body {
      padding: 10px 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 90px;
      align-items: center;
      font-size: var(--font-size-small);
      font-weight: var(--font-weight500);
      color: var(--secondary-navy-blue);
    }

    .footer {
      padding: 10px 20px 20px;

      .timestamp {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight500);
        color: var(--secondary-gray500);
        margin-bottom: 10px;
      }

      .details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;

        .detailsSection {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .icon {
            stroke: var(--gray50);
            margin-right: 5px;
          }

          .title {
            font-size: var(--font-size-small);
            color: var(--gray50);
          }
        }
      }

      .footerBtnSection {
        background-color: transparent;
        width: 100%;

        .btn_wrapper {
          width: auto;

          .tertiary_btn {
            width: auto;
          }
        }
      }
    }
  }

  // TWITTER TOP FOLLOWERS CARD
  .card.topFollowersCard {
    width: 100%;
    background-color: var(--light) !important;
    padding: 0;

    .headerWrapper {
      .header {
        width: 100%;

        .headerImg {
          width: 100%;
          max-height: 200px;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }
      }

      .profileImgWrapper {
        width: 80px;
        height: 80px;
        margin-top: -50px;
        margin-left: 20px;

        .profileImm {
          width: auto;
          height: 100%;
          border: 1px solid var(--secondary-gray500);
          border-radius: 100%;
        }
      }
    }

    .body {
      margin-top: -50px;
      margin-left: 100px;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleWrapper {
        .name {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: var(--font-size-normal);
          font-weight: var(--font-weight700);
          color: var(--secondary-navy-blue);

          .verifiedIconWrapper {
            width: 20px;
            height: 20px;
            margin-left: 5px;

            .verifiedIcon {
              width: 100%;
              height: auto;
            }
          }
        }

        .username {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: var(--font-size-small);
          font-weight: var(--font-weight500);
          color: var(--secondary-gray500);
        }
      }

      .detailsSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: var(--font-size-small);
          color: var(--secondary-navy-blue);
          font-weight: var(--font-weight700);
        }

        .subtitle {
          font-size: var(--font-size-xsmall);
          color: var(--gray50);
          font-weight: var(--font-weight500);
        }
      }
    }

    .footer {
      padding: 10px 20px 20px;

      .details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;

        .detailsSection {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .title {
            font-size: var(--font-size-small);
            color: var(--secondary-navy-blue);
            font-weight: var(--font-weight700);
          }

          .subtitle {
            font-size: var(--font-size-xsmall);
            color: var(--gray50);
            font-weight: var(--font-weight500);
          }
        }
      }

      .footerBtnSection {
        background-color: transparent;
        width: 100%;

        .btn_wrapper {
          width: auto;

          .tertiary_btn {
            width: auto;
          }
        }
      }
    }
  }

  // VIDEO THUMBNAIL CARD
  .card.videoThumbnailCard {
    position: relative;
    margin-bottom: 10px;
    background-color: transparent;
    overflow: hidden;

    .thumbnailImg {
      height: 300px;
      transform: scale(1, 1);
      border-radius: 7px;
      transition: all 500ms ease-in-out;
    }


    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;

      .header {
        width: 100%;

        .header_wrapper {
          width: 100%;
          height: 50px;
          background: linear-gradient(180deg, #000, transparent);
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;

          .badge_wrapper {
            padding: 10px;
            display: flex;
            justify-content: end;
            align-items: center;

            .button_badge {
              height: auto;
              font-size: 12px;
              line-height: 1;
              padding: 5px 10px;
              background: #fff;
            }
          }
        }
      }

      .overlayDetailsWrapper {
        width: 100%;
        height: 100px;
        background: linear-gradient(180deg, transparent, #000);
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: row;

        .overlayDetails {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;

          .iconWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 10px;

            .icon {
              height: 30px;
              width: auto;
            }
          }

          .detailsWrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .numberDetails {
              .title {
                font-size: var(--font-size-small);
                color: var(--light);
                font-weight: 500;
                margin-bottom: 0;
                line-height: 1;
              }

              .subtitle {
                font-size: var(--font-size-xsmall);
                color: var(--light);
                font-weight: 400;
                margin-bottom: 0;
                line-height: 1.9;
              }
            }
          }

          .videoLengthWrapper {
            .videoLength {
              font-size: var(--font-size-small);
              color: var(--light);
              font-weight: 400;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .videoThumbnailCard-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      color: white;
      height: 20px;
      width: 20px;
      padding: 4px 5px;
      border-radius: 100%;
      display: flex;
      background: rgba(104, 104, 104, 0.6);
      justify-content: center;
      align-items: center;
      font-size: 0px;
      cursor: pointer;
      transition:
        all 250ms ease-in,
        font-size 245ms ease-out;

      &:hover {
        width: auto;
        border-radius: 5px;
        justify-content: start;
        font-size: 11px;

        svg {
          margin-right: 5px;
        }
      }

      svg {
        height: 15px;
        width: 15px;
      }
    }

    &:hover {
      cursor: pointer;

      .thumbnailImg {
        transform: scale(1.15, 1.15);
      }
    }

  }

  .card.videoThumbnailCard.active {
    border-color: var(--primary500);

    .active_arrow {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--primary500);
      position: absolute;
      bottom: -14px;
      right: calc(50% - 10px);
      z-index: 999999 !important;
      margin: auto;
    }
  }

  // ADMIN CAMPAIGN BUCKET LIST CARDS
  .card.bucketListCard {
    width: 100% !important;

    .card_body {
      padding: 20px;

      .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid var(--gray300);
        padding-bottom: 10px;
        margin-bottom: 10px;

        .title {
          font-size: 1rem;
          color: var(--gray900);
          font-weight: var(--font-weight400);
          line-height: 1;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          min-height: 25px;

          span {
            color: var(--secondary-gray600);
            margin: 0 10px;
          }

          .icon_wrapper {
            width: 25px;
            height: 25px;
            margin-right: 10px;

            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .subtitle {
          font-size: 0.9rem;
          line-height: 1;
          color: var(--secondary-gray600);
          font-weight: var(--font-weight400);
          margin-bottom: 10px;
        }

        .list {
          width: 100%;
          height: 100px;
          overflow-y: auto;

          ol {
            padding-left: 30px;
            margin: 0;

            li {
              width: calc(100% - 20px);
              font-size: 1rem;
              line-height: 1;
              color: var(--gray900);
              font-weight: var(--font-weight400);
              padding: 10px;
              border-bottom: 1px solid var(--gray300);
              word-break: break-word;

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }

      .row.campaign_count_wrapper {
        align-items: center;

        .title {
          font-size: 3rem;
          color: var(--primary500);
          font-weight: var(--font-weight700);
          line-height: 1;
        }
      }
    }

    .card_footer {
      // padding: 0 20px 20px 20px;
      padding: 0px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      background-color: transparent;

      // .btn_wrapper {
      //   padding: 0;
      .theme_button {
        width: auto;
        padding: 5px 10px;
        height: auto;
        font-size: 14px;
        min-width: 100px;
      }

      // }
    }
  }
}

.section_card {
  position: relative;
  background-color: var(--light);
  box-shadow: 0 3px 10px rgba(215, 170, 226, 0.23);
  border-radius: 7px;
  min-height: 190px;
  // height: max-content;
  word-wrap: break-word;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  // border: 1px solid var(--gray300);

  .section_card_header {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .filters_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;

      .filter {
        width: 250px;
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .section_dropdown {
          width: 100%;
          max-width: 250px;
        }

        @media (max-width: 768px) {
          width: 100%;
          margin: 0px;
          padding-top: 20px;
        }

        @media (max-width: 768px) {
          padding-top: 10px;
        }
      }

      .filter.export_filter {
        width: 40px !important;
        margin-right: 0 !important;
        margin: 0 !important;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .form_group {
      .input {
        max-width: 300px;

        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }

    .section_dropdown {
      max-width: 250px;
    }

    .section_dropdown.small-dropdown {
      width: 100%;
      max-width: 200px;
    }

    @media (max-width: 576px) {
      flex-wrap: wrap;
      padding: 10px;

      .section_card_title {
        margin-bottom: 10px;
      }
    }
  }

  .section_card_body {
    width: 100%;
    height: 100%;
    position: relative;

    .section_card_chart_wrapper {
      width: 100%;
      height: 350px;
      padding: 20px;
      // padding-bottom: 0;
      position: relative;

      .section_card_chart {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 576px) {
        padding: 10px;
      }
    }

    .section_card_chart_wrapper+.footer_title_wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: baseline;

      .footer_title {
        font-size: 18px;
        font-weight: 500;
        color: var(--secondary-navy-blue);
      }
    }

    .section_card_map_wrapper {
      width: 100%;
      height: 350px;
      padding: 20px;
      background-color: transparent;
      max-height: 350px;

      .map_section {
        width: 100%;
        height: 100%;
        max-height: 310px;
      }
    }

    .section_card_img_wrapper {
      background-color: transparent;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;

      .img_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 250px;
        max-height: 250px;
        overflow-y: auto;

        .section_img {
          width: 100%;
          height: auto;
        }
      }
    }

    .section_icon {
      max-height: 220px;
    }
  }

  .form_group {
    .form_dropdown {
      width: 100% !important;
      max-width: 100%;
    }
  }

  .card_border_right {
    position: relative;

    &:after {
      content: "";
      height: 80%;
      width: 1px;
      position: absolute;
      right: -10px;
      background-color: var(--gray300);
      top: 10%;
    }

    @media (max-width: 767px) {
      &:after {
        content: "";
        height: 0%;
        width: 0px;
        position: absolute;
        right: 0px;
        background-color: transparent;
        top: 0%;
      }
    }
  }
}

.section_card.section_dropdown_header {
  position: relative;
  background-color: transparent;
  border-radius: 0;
  min-height: auto;
  word-wrap: break-word;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: none;
  box-shadow: none !important;

  .section_card_header {
    padding-left: 0;
    padding-right: 0;
  }
}

.grid.section_card {
  grid-row-gap: 0;
}

.section_card.bgTransparent {
  background-color: #ffffff !important;
  box-shadow: none !important;
}

.section_card.bgBorderTransparent {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
}

.section_card.market_card {
  .section_card_body {
    .section_card_chart_wrapper {
      height: 200px;

      // width: calc(100% - 40px);
      .section_card_chart {
        width: calc(100% - 40px);
      }
    }

    .table_wrapper {
      padding-top: 0;

      .table {
        tbody {
          tr {
            td {
              padding: 0.938rem;
              max-width: 200px;

              &:first-child {
                padding-left: 10px;
              }

              &:last-child {
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

// TO HIDE THE TOOLTIP DATA FROM EXPORT
div[role="tooltip"] {
  color: transparent;
  display: none;
}

.section_card.max_width {
  max-width: 600px;
}

.section_card.fullpage_section_card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

// BADGE
.badge {
  background-color: var(--secondary-gray500);
  opacity: 0.5;
  width: 15px;
  height: 15px;
  color: var(--light);
  font-size: 11px;
  line-height: 1;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.badge_hover {
  display: none;
}

.badge:hover+.badge_hover {
  display: block;
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: var(--secondary-navy-blue);
  border-radius: 7px;
  border: none;
  color: var(--secondary-gray500);
  font-size: 12px;
  font-weight: var(--font-weight400);
  padding: 10px;
  max-width: 150px;
  text-align: center;
  line-height: 1.4;
  z-index: 999;
  white-space: break-spaces;
  overflow-wrap: break-word;
}

// DATE BADGE
.date_badge_section {
  height: 100%;
  max-height: 40px;
  padding: 0 20px;

  .date_badge_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .date_badge_title {
      font-size: var(--h3-font-size);
      font-weight: var(--font-weight500);
    }

    .badge {
      opacity: 1;
      font-size: 14px;
      border-radius: 5px;
      padding: 5px 5px 5px 10px;
      margin-left: 20px;
      font-weight: var(--font-weight500);
      color: var(--secondary-gray700);
      background-color: var(--secondary-gray400);
      transition: all 1s ease-out;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: auto;
      transition: all 1s ease;

      .close_icon {
        display: block;
        height: 28px;
        width: 28px;
        border-radius: 100%;
        border: none;
        border-radius: 100%;
        position: relative;
        background-color: transparent;

        &:hover {
          cursor: pointer;
        }

        &::before {
          content: "";
          width: 14px;
          height: 1px;
          background: #8e8e8e;
          border-radius: 20px;
          left: 50%;
          top: calc(50% - 1px);
          display: block;
          position: absolute;
          margin-left: -6px;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          transition: all 300ms ease;
        }

        &::after {
          content: "";
          width: 14px;
          height: 1px;
          background: #8e8e8e;
          border-radius: 20px;
          left: 50%;
          bottom: calc(50% - 1px);
          display: block;
          position: absolute;
          margin-left: -6px;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          transition: all 300ms ease;
        }

        &::before {
          transform: rotate(45deg);
          top: 50%;
        }

        &:after {
          transform: rotate(-45deg);
          bottom: 50%;
        }
      }
    }
  }
}

// HORIZONTAL DIVIDER
.horizontal_divider {
  height: 2px;
  width: 100%;
  background-color: var(--gray100);
  margin: 10px 0;
}

// GROWTHCONVERTER
.card_growth_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .icon_section {
    margin-right: 5px;
    width: 0.813rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text_section {
    font-size: 0.875em;
    font-weight: var(--font-weight500);
    line-height: 1.4;
    text-align: left;
  }

  .text_section.positive_growth {
    color: var(--secondary-green500);
  }

  .text_section.negative_growth {
    color: var(--secondary-red500);
  }

  .text_section.neutral_growth {
    color: var(--secondary-gray500);
  }
}

// PROGRESSBAR
.progressbar_wrapper {
  position: relative;

  .progressbar_section {
    position: relative;
    width: 100%;
    height: 5px;
    margin-top: 10px;

    .progressbar_bg {
      background-color: var(--gray200);
      border-radius: 50px;
      width: 100%;
      height: 100%;

      .progressbar_range {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50px;
      }

      .progressbar_target {
        background-color: var(--gray500);
        height: 100%;
        width: 3px;
        height: 9px;
        position: absolute;
        z-index: 99;
        top: -2px;
      }

      .progressbar_current {
        background-color: var(--secondary-yellow500);
        height: 100%;
      }
    }
  }

  .progressbar_hover {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
    background-color: var(--secondary-navy-blue);
    border-radius: 7px;
    border: none;
    color: var(--secondary-gray500);
    font-size: 12px;
    font-weight: var(--font-weight400);
    padding: 10px;
    width: 100%;
    max-width: 150px;
    text-align: center;
    line-height: 1.4;
    z-index: 999;

    .row {
      margin-bottom: 5px;
      text-align: left;

      .hover_title {
        color: var(--light);
        font-size: 14px;
        line-height: 1.2;
        font-weight: var(--font-weight500);
      }
    }
  }
}

.card_progressbar {
  &:hover {
    .progressbar_wrapper {
      .progressbar_hover {
        display: block;
      }
    }
  }
}

// FLEXBOX
.flex {
  display: flex;
}

.flex.row {
  flex-direction: row;
}

.flex.column {
  flex-direction: column;
}

.flex.justify_center {
  justify-content: center;
}

.flex.justify_between {
  justify-content: space-between;
}

.flex.justify_end {
  justify-content: flex-end !important;
}

.flex.justify_start {
  justify-content: flex-start;
}

.flex.align_start {
  align-items: start;
}

.flex.align_center {
  align-items: center;
}

.flex.align_end {
  align-items: flex-end;
}

.flex.wrap {
  flex-wrap: wrap;
}

// DROPDOWN
.dropdown {
  .css-b62m3t-container {
    .css-16b15g8-control {
      width: inherit;

      .css-g1d714-ValueContainer {
        flex-wrap: nowrap !important;
      }
    }

    &:hover {
      cursor: pointer;

      .css-14el2xx-placeholder {
        color: var(--primary500) !important;
      }

      .css-tlfecz-indicatorContainer {
        color: var(--primary500) !important;
      }
    }
  }
}

.css-3w2yfm-ValueContainer {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.css-g1d714-ValueContainer {
  padding: 0 8px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
}

.css-1dyz3mf {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.top_nav_dropdown {
  .css-b62m3t-container {
    .css-t1s8vm-control {
      .css-g1d714-ValueContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}

.dropdown.top_nav_dropdown {
  width: 300px;
  max-width: 300px;
}

.dropdown.header_dropdown {
  width: 100%;
  max-width: 300px;
}

.dropdown.section_dropdown {
  width: 100%;
  max-width: 250px;
}

.css-17q4nnh-control {
  width: inherit;

  .css-g1d714-ValueContainer {
    flex-wrap: nowrap !important;
  }
}

// CAMPAIGN DROPDOWN
.dropdown.campaign_dropdown {
  width: 300px;
  max-width: 100%;
  padding: 0;
  position: relative;
  height: 40px;
  background-color: var(--light);
  border-radius: 5px;
  border: 1px solid var(--gray50);
  padding: 0px 10px;
  color: var(--dropdown-options);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
}

// BRANDS DROPDOWN
.brands_dropdown_container {
  .brands_dropdown.filter_active {

    .theme_button.secondary_btn.active,
    .theme_button {
      border-color: var(--primary500) !important;
      background-color: var(--primary500) !important;

      &:hover {
        border-color: var(--primary500) !important;
        background-color: var(--primary500) !important;

        .notification_wrapper {
          color: var(--light);
        }

        .icon_wrapper {
          .btn_icon {
            stroke: var(--light) !important;
          }
        }
      }

      .notification_wrapper {
        font-size: 18px;
        font-weight: 300;
        color: var(--light);
      }

      .icon_wrapper {
        .btn_icon {
          stroke: var(--light) !important;
        }
      }
    }
  }

  .brands_dropdown {
    .secondary_btn {
      border-color: var(--gray50);

      .icon_wrapper {
        padding: 5px;

        .btn_icon {
          stroke: var(--gray50) !important;
        }
      }

      &.active,
      &:hover {
        background-color: transparent !important;
        border-color: var(--primary500);

        .icon_wrapper {
          .btn_icon {
            stroke: var(--primary500) !important;
          }
        }
      }
    }
  }

  .brands_dropdown_wrapper {
    // background-color: red;
    background-color: var(--light);
    // width: 100%;
    width: max-content;
    min-width: 300px;
    max-width: 800px;
    height: auto;
    border: 1px solid var(--gray50);
    border-radius: 7px;
    box-shadow:
      0 2px 5px 0 rgba(0, 0, 0, 0.26),
      0 2px 10px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;

    .brands_dropdown_section {
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .brands_container {
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: var(--light);
        display: flex;
        flex-direction: column;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-right: 1px solid #eff2f7;

        .header {
          font-size: 14px;
          font-weight: var(--font-weight400);
          color: var(--secondary-gray500);
          padding: 5px 10px;
        }

        .body {
          overflow-y: auto;
          height: 300px;

          .tab {
            font-size: var(--h5-font-size);
            font-weight: var(--font-weight400);
            color: var(--secondary-gray700);
            padding: 5px 10px;

            // padding: 5px;
            &.active {
              background-color: rgba(17, 161, 253, 1);
              color: var(--light);
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .campaigns_container {
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: var(--light);
        display: flex;
        flex-direction: column;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-right: 1px solid #eff2f7;

        .header {
          font-size: 14px;
          font-weight: var(--font-weight400);
          color: var(--secondary-gray500);
          padding: 5px 10px;
        }

        .body {
          padding: 5px 10px;

          .filter {
            margin-bottom: 5px;
          }

          .tabs_container {
            height: 260px;
            overflow-y: auto;

            .tab {
              font-size: var(--h5-font-size);
              font-weight: var(--font-weight400);
              color: var(--secondary-gray700);
              padding: 5px 10px;

              // padding: 5px;
              &.active {
                background-color: rgba(17, 161, 253, 0.7);
                color: var(--light);
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .adsname_container {
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: var(--light);
        display: flex;
        flex-direction: column;
        border-top-right-radius: 7px;
        border-right: 1px solid #eff2f7;

        .header {
          font-size: 14px;
          font-weight: var(--font-weight400);
          color: var(--secondary-gray500);
          padding: 5px 10px;
        }

        .body {
          padding: 5px 10px;

          .filter {
            margin-bottom: 5px;
          }

          .tabs_container {
            height: 260px;
            overflow-y: auto;

            .tab {
              font-size: var(--h5-font-size);
              font-weight: var(--font-weight400);
              color: var(--secondary-gray700);
              padding: 5px 10px;

              // padding: 5px;
              &.active {
                background-color: rgba(17, 161, 253, 0.5);
                color: var(--light);
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .brands_dropdown_footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid #eff2f7;

      .btn_wrapper {
        padding: 10px;
        min-width: 150px;
      }
    }
  }
}

// CAMPAIGNS DROPDOWN
.multistep_dropdown_container {
  .dropdown_wrapper_btn {
    .secondary_btn {
      border-color: var(--gray50);

      .icon_wrapper {
        padding: 5px;

        .btn_icon {
          stroke: var(--gray50) !important;
        }
      }

      &.active,
      &:hover {
        background-color: transparent !important;
        border-color: var(--primary500);

        .icon_wrapper {
          .btn_icon {
            stroke: var(--primary500) !important;
          }
        }
      }
    }
  }

  .dropdown_wrapper {
    // background-color: red;
    background-color: var(--light);
    width: 100%;
    min-width: 300px;
    max-width: 800px;
    height: auto;
    border: 1px solid var(--gray50);
    border-radius: 7px;
    box-shadow:
      0 2px 5px 0 rgba(0, 0, 0, 0.26),
      0 2px 10px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;

    .tabs_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      .tab {
        ul {
          .tab_item {
            font-size: 18px;
          }
        }
      }
    }

    .brands_dropdown_section {
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .brands_container {
        width: 190px;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: var(--light);
        display: flex;
        flex-direction: column;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-right: 1px solid #eff2f7;

        .header {
          font-size: 14px;
          font-weight: var(--font-weight400);
          color: var(--secondary-gray500);
          padding: 5px 10px;
        }

        .body {
          overflow-y: auto;
          height: 300px;

          .tab {
            font-size: var(--h5-font-size);
            font-weight: var(--font-weight400);
            color: var(--secondary-gray700);
            padding: 5px 10px;

            // padding: 5px;
            &.active {
              background-color: rgba(17, 161, 253, 1);
              color: var(--light);
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .campaigns_container {
        width: 210px;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: var(--light);
        display: flex;
        flex-direction: column;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-right: 1px solid #eff2f7;

        .header {
          font-size: 14px;
          font-weight: var(--font-weight400);
          color: var(--secondary-gray500);
          padding: 5px 10px;
        }

        .body {
          padding: 5px 10px;

          .filter {
            margin-bottom: 5px;
          }

          .tabs_container {
            height: 260px;
            overflow-y: auto;

            .tab {
              font-size: var(--h5-font-size);
              font-weight: var(--font-weight400);
              color: var(--secondary-gray700);
              padding: 5px 10px;

              // padding: 5px;
              &.active {
                background-color: rgba(17, 161, 253, 0.7);
                color: var(--light);
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .adsname_container {
        width: 398px;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: var(--light);
        display: flex;
        flex-direction: column;
        border-top-right-radius: 7px;
        border-right: 1px solid #eff2f7;

        .header {
          font-size: 14px;
          font-weight: var(--font-weight400);
          color: var(--secondary-gray500);
          padding: 5px 10px;
        }

        .body {
          padding: 5px 10px;

          .filter {
            margin-bottom: 5px;
          }

          .tabs_container {
            height: 260px;
            overflow-y: auto;

            .tab {
              font-size: var(--h5-font-size);
              font-weight: var(--font-weight400);
              color: var(--secondary-gray700);
              padding: 5px 10px;

              // padding: 5px;
              &.active {
                background-color: rgba(17, 161, 253, 0.5);
                color: var(--light);
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .brands_dropdown_footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid #eff2f7;

      .btn_wrapper {
        padding: 10px;
        min-width: 150px;
      }
    }
  }
}

// SALES MARKETING CATEGORY BRAND DROPDOWN

// EXPORT DROPDOWN
.export_dropdown_wrapper {
  position: relative;

  .export_dropdown_btn_wrapper {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    .export_dropdown_btn_icon {
      fill: var(--secondary-gray400);
      width: 6px;
    }

    &.active,
    &:hover:not([data-disabled="true"]) {
      background-color: transparent;
      cursor: pointer;

      .export_dropdown_btn_icon {
        fill: var(--primary500);
      }
    }

    &[data-disabled="true"] {
      cursor: not-allowed;
    }
  }

  .options_wrapper {
    position: absolute;
    top: 34px;
    right: 0;
    background-color: var(--light);
    border-radius: 7px;
    z-index: 9999 !important;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow:
      0 2px 5px 0 rgba(0, 0, 0, 0.26),
      0 2px 10px 0 rgba(0, 0, 0, 0.16);

    .tab {
      background-color: var(--light);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: var(--dropdown-options);
      height: 40px;

      &:first-child {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }

      &:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }

      &:hover {
        background-color: var(--dropdown-bg);
        cursor: pointer;
      }

      a {
        color: var(--dropdown-options) !important;
      }
    }
  }
}

// BADGES
.badges_list_wrapper_body {
  padding-bottom: 20px;

  .badges_list_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 20px 20px;
    height: 100%;
    // max-height: 250px;
    max-height: 100px;
    overflow-y: auto;

    @media (max-width: 576px) {
      padding: 0 10px 10px;
    }
  }

  @media (max-width: 576px) {
    padding-bottom: 10px;
  }
}

// ALERT
.alert_section {
  padding: 20px 20px 0;

  .alert {
    background-color: transparent;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }

  .info_alert {
    background-color: var(--primary-hover200);
    border-color: var(--primary-hover200);
    color: var(--primary700);
  }
}

// DATEPICKER
.datepicker_wrapper {
  background-color: var(--secondary-light);
  border: 1px solid var(--gray50);
  border-radius: 7px;
  width: 280px;
  height: 40px;

  .datepicker_section {
    display: flex;
    justify-content: space-between;
    height: inherit;
    padding: 0 10px;
    align-items: center;

    .datepicker {
      width: inherit;
      height: inherit;
      background-color: inherit;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      .date_title {
        color: var(--dropdown-options);
        font-size: var(--font-size-normal);
        font-weight: var(--font-weight400);
      }

      .date_subtitle {
        color: var(--dropdown-options);
        font-size: var(--font-size-normal);
        font-weight: var(--font-weight400);
        margin: 0 10px;
      }
    }

    .icon {
      height: 20px;
      width: auto;
      margin: 0 5px;
      stroke: var(--dropdown-options);
    }
  }

  &:hover {
    cursor: pointer;
    border-color: var(--primary500);

    .datepicker {

      .date_title,
      .date_subtitle {
        color: var(--primary500);
      }
    }

    .icon {
      stroke: var(--primary500);
    }
  }
}

.datepicker_dropdown_wrapper {
  background-color: var(--light);
  border-radius: 7px;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.26),
    0 2px 10px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 60px;
  right: 10px;
  z-index: 1000;

  .datepicker_calender {
    .rdrDefinedRangesWrapper {
      .rdrStaticRanges {
        .rdrStaticRange {
          .rdrStaticRangeLabel {
            padding: 9px 20px !important;
          }
        }
      }

      .rdrInputRanges {
        display: none;
      }
    }
  }

  .rdrDayToday .rdrDayNumber span::after {
    display: none !important;
  }

  .rdrCalendarWrapper {
    color: #3d3d3d;
    font-size: 12px;
  }

  .rdrDayNumber {
    font-weight: 400;
  }

  .datepicker_footer {
    display: flex;
    flex-direction: row;

    .btn_wrapper {
      padding: 10px;
      width: 100%;
    }
  }
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  border-bottom-color: transparent !important;
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  border-bottom-color: #eff2f7 !important;
  //   @include xs {
  //     border-bottom-color: #fff !important;
  //   }
}

// LOADER
.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .loader_container {
    width: 80px;
    height: 80px;
  }
}

svg {
  width: 100%;
}

#redIcon {
  animation: redIconAnimation 10s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes redIconAnimation {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

#greyIcon {
  animation: greyIconAnimation 5s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
  animation-delay: 0.01s;
}

@keyframes greyIconAnimation {
  from {
    transform: rotateZ(360deg);
  }

  to {
    transform: rotateZ(0deg);
  }
}

// SKELETON LOADER
.skeleton {
  opacity: 0.7;
  animation: skeleton_loading 1s linear infinite alternate;
}

.chart_skeleton_background {
  width: 100%;
  height: 100%;
  background-color: transparent;
  // border-bottom: 2px solid var(--gray50);
  // border-left: 2px solid var(--gray50);
  display: grid;
  place-items: center;
  margin: 0;
}

.area_chart {
  /* Reset */
  margin: 0;
  padding: 0;
  border: 0;

  /* Dimensions */
  width: 100%;
  max-width: 100%;
  height: 100%;

  /* Layout */
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: row;
}

ul.area_chart,
ol.area_chart {
  list-style: none;
}

.area_chart>* {
  /* Even size items */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  /* Color */
  background: var(--gray50);
  clip-path: polygon(0% calc(100% * (1 - var(--start))), 100% calc(100% * (1 - var(--end))), 100% 100%, 0% 100%);
  opacity: 0.7;
  animation: skeleton_loading 1s linear infinite alternate;
}

.skeleton_text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

#axis {
  opacity: 0.5;
  animation: axis_skeleton_loading 1s linear infinite alternate;

  #axisLine {
    animation: axis_line_skeleton_loading 1s linear infinite alternate;
  }
}

#areachart {
  opacity: 0.5;
  animation: area_skeleton_loading 1s linear infinite alternate;
}

@keyframes axis_skeleton_loading {
  0% {
    fill: hsl(200, 20%, 70%);
  }

  100% {
    fill: hsl(200, 20%, 95%);
  }
}

@keyframes axis_line_skeleton_loading {
  0% {
    stroke: hsl(200, 20%, 70%);
  }

  100% {
    stroke: hsl(200, 20%, 95%);
  }
}

@keyframes area_skeleton_loading {
  0% {
    fill: hsl(200, 20%, 70%);
  }

  100% {
    fill: hsl(200, 20%, 95%);
  }
}

@keyframes skeleton_loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

// FORM
.form_wrapper {
  width: 100%;
  padding: 20px;

  .form_header {
    .form_header_logo {
      margin-bottom: 20px;

      .logo {
        height: 40px;
      }
    }

    .form_steps_wrapper {
      width: 100%;
      max-width: 300px;
      padding: 0;
      position: relative;

      .form_steps_path_wrapper {
        width: 100%;
        background-color: var(--gray500);
        height: 2px;
        position: relative;
        margin-top: 14px;

        .active {
          background-color: var(--primary500);
        }
      }

      .form_steps_header_wrapper {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 99;

        .form_steps_header {
          width: 30px;
          height: 30px;
          font-size: 14px;
          font-weight: var(--font-weight400);
          color: var(--gray500);
          background-color: var(--light);
          border: 2px solid;
          border-color: var(--gray500);
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            cursor: pointer;
          }
        }

        .active {
          border-color: var(--primary500);
          background-color: var(--primary500);
          color: var(--light);
        }
      }
    }
  }

  .form {
    .form_group_container {
      // max-height: 400px;
      max-height: 40vh;
      height: 100%;
      overflow: auto;
      padding: 0;
    }

    .form_group {
      padding: 10px 10px 25px;
      position: relative;

      .form_control {
        height: 40px;
        background-color: var(--gray100);
        border-radius: 5px;
        border: 1px solid var(--gray100);
        width: 100%;
        padding: 10px 15px;
        font-size: 14px;
        color: var(--secondary-navy-blue);

        &:focus-visible {
          border-color: var(--primary500);
          background-color: rgba(17, 161, 253, 0.1);
          outline: none;
        }
      }

      .form_control.invalid {
        border-color: var(--secondary-red500);
        background: rgba(213, 31, 48, 0.1);
        color: var(--secondary-red500);
      }

      .form_control .input {
        border: 1px solid var(--gray700);
        color: var(--secondary-navy-blue);
      }

      .form_group_btn_wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 40px;
        width: 40px;

        .show_password_btn {
          background-color: transparent;
          height: 100%;
          width: 100%;
          border: none;
          cursor: pointer;

          .icon {
            fill: var(--gray500);
          }
        }
      }

      .form_dropdown {
        .css-t1s8vm-control {
          height: 40px;
          background-color: var(--gray100);
          border-radius: 5px;
          border: 1px solid var(--gray100);
          color: var(--secondary-navy-blue);
          font-size: 14px;

          .css-qc6sy-singleValue {
            font-size: 14px;
          }

          &:hover {
            background-color: var(--gray100);
            border-color: var(--gray100);
            color: var(--secondary-navy-blue);

            .css-qc6sy-singleValue {
              color: var(--secondary-navy-blue);
            }
          }
        }
      }

      .form_dropdown.invalid {
        .css-t1s8vm-control {
          border-color: var(--secondary-red500);
          background: rgba(213, 31, 48, 0.1);
          color: var(--secondary-red500);
        }
      }

      .link {
        font-size: 14px;
        color: var(--primary500);
        text-decoration: none;

        &:hover {
          color: var(--secondary-navy-blue);
        }
      }

      .error_section {
        background-color: transparent;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 10px;
      }
    }

    .form_group.invalid {
      .form_control {
        border-color: var(--secondary-red500) !important;
      }
    }

    .help_text {
      font-size: 14px;
    }
  }

  .form_subtitle {
    font-weight: var(--font-weight500);
    color: var(--secondary-navy-blue);
    margin-top: 10px;
  }
}

// TAB
.tab {
  background-color: transparent;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  .primary_navigation {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid var(--secondary-gray500);

    .tab_item {
      padding: 15px;
      display: flex;
      align-items: center;
      font-size: var(--h3-font-size);
      line-height: 1;
      font-weight: var(--font-weight500);
      color: var(--secondary-gray700);
      border-bottom: 2px solid transparent;
      margin-bottom: -2px;

      &:hover {
        cursor: pointer;
      }
    }

    .tab_item.active {
      color: var(--primary500);
      border-color: var(--primary500);
    }
  }

  .secondary_navigation {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: var(--light);
    border: 1px solid var(--light);
    border-radius: 7px;

    .tab_item {
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      font-size: var(--font-size-small);
      line-height: 1;
      font-weight: var(--font-weight500);
      color: var(--secondary-gray700);
      border-radius: 7px;

      &:hover {
        cursor: pointer;
      }
    }

    .tab_item.active {
      background-color: var(--primary500);
      color: var(--light);
      border-color: var(--primary500);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

// LOGIN
.form_container {
  padding: 20px;
  width: 100%;
  max-width: 500px;
  height: max-content;
  max-height: 100%;
  max-height: calc(100vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

// DASHBOARD
.dashboard_container {
  width: 100%;
  height: 100%;
  // background-color: var(--body-bg);
  background-color: transparent;

  // HEADER
  .dashboard_navbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 70px;
    // position: sticky;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    background-color: var(--light);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

    .icogz_logo_link {
      height: 40px;

      .icogz_logo {
        height: 100%;
        padding: 0 20px;
      }
    }

    .mobile_navbar_filter {
      display: none;
    }

    #top_nav_bar {
      height: 100%;
      transform: none;
      transition: transform 600ms cubic-bezier(0.535, 0, 0, 1);
      margin: 0;
      flex-grow: 1;
      align-items: center;

      .top_nav_page_title {
        padding: 0 20px;
        border-left: 1px solid var(--gray50);
        font-size: var(--font-size-large);
        color: var(--secondary-navy-blue);
        font-weight: var(--font-weight700);
      }

      .top_nav_options {
        padding: 0 10px;
        align-items: center;

        .export_dropdown_wrapper {
          margin-left: 0 !important;
        }

      }
    }

    @media (max-width: 576px) {
      justify-content: space-between;

      .icogz_logo_link {
        margin: 10px 0;
      }

      .mobile_navbar_filter {
        display: flex;
      }

      #top_nav_bar {
        display: none;

        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 1rem;
          padding-right: 20px;
        }
      }
    }
  }

  // SIDEBAR
  .dashboard_sidebar {
    .sidebar {
      .module_sidebar {
        .sidebar_wrapper {
          .icons_sidebar {
            width: 60px;
            position: fixed;
            top: 0px;
            bottom: 0;
            left: 0;
            z-index: 100;
            padding: 0;
            padding-top: 70px;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            background-color: var(--secondary-navy-blue);
            //transition: all 0.3s ease-in;
            // @media (min-width: 1600px) {
            //   display: none;
            // }

            .icons_sidebar_scrollbar {
              display: flex;
              flex-direction: column;
              height: calc(100vh - 210px);
              overflow: auto;

              .sidebar_nav {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .nav_link {
                  font-size: 14px;
                  font-weight: var(--font-weight500);
                  line-height: 1;
                  font-weight: normal;
                  color: var(--light);
                  text-decoration: none;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  cursor: pointer;
                  -webkit-transition: all 200ms ease;
                  -moz-transition: all 200ms ease;
                  -ms-transition: all 200ms ease;
                  -o-transition: all 200ms ease;
                  transition: all 200ms ease;
                  border-radius: 7px;
                  padding: 10px;
                  margin: 10px 0;

                  .nav_link_icon {
                    stroke: var(--gray50);
                    height: 20px;
                  }

                  &:hover {
                    background-color: var(--light);
                    color: var(--secondary-navy-blue);

                    .nav_link_icon {
                      stroke: var(--secondary-navy-blue);
                    }
                  }
                }

                .nav_link.active,
                .nav_link.active:hover {
                  background-color: var(--light);
                  color: var(--secondary-navy-blue);

                  .nav_link_icon {
                    stroke: var(--secondary-navy-blue);
                  }
                }
              }
            }

            .icons_sidebar_footer {
              display: flex;
              height: 140px;
              width: 100%;
              padding: 10px;
              justify-content: flex-end;
              align-items: center;
              flex-direction: column;

              .profile_wrapper {
                height: inherit;
                width: inherit;
                background-color: var(--secondary-navy-blue);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                text-decoration: none;
                height: 60px;
                border-radius: 7px;
                margin-bottom: 10px;

                .icon_wrapper {
                  height: 40px;
                  width: 40px;
                  border-radius: 100%;

                  .profile_icon {
                    width: 100%;
                    height: auto;
                    border-radius: 100%;
                  }
                }
              }

              .sidebar_nav {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .nav_link {
                  font-size: 14px;
                  font-weight: var(--font-weight500);
                  line-height: 1;
                  font-weight: normal;
                  color: var(--light);
                  text-decoration: none;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  cursor: pointer;
                  -webkit-transition: all 200ms ease;
                  -moz-transition: all 200ms ease;
                  -ms-transition: all 200ms ease;
                  -o-transition: all 200ms ease;
                  transition: all 200ms ease;
                  border-radius: 7px;
                  padding: 10px;

                  .nav_link_icon {
                    stroke: var(--light);
                    height: 20px;
                  }

                  &:hover {
                    background-color: var(--light);
                    color: var(--secondary-navy-blue);

                    .nav_link_icon {
                      stroke: var(--secondary-navy-blue);
                    }
                  }
                }
              }
            }
          }

          .detailed_sidebar {
            // width: 15%;
            width: 340px;
            position: fixed;
            top: 0px;
            bottom: 0;
            left: 0;
            // max-width: 90%;
            z-index: 100;
            padding: 0;
            padding-top: 70px;
            background-color: var(--secondary-navy-blue);
            // background-color: transparent;
            overflow-y: auto;
            /*    -webkit-transition: all 300ms ease;
            -moz-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease;
            transition: all 300ms ease; */
            overflow-x: visible;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .burger_menu_wrapper {
              // height: 80px;
              height: 60px;
              background-color: var(--secondary-navy-blue);

              // @media (min-width: 1600px) {
              //   display: none;
              // }

              .burger_menu {
                display: block;
                height: 40px;
                width: 40px;
                border-radius: 100%;
                border: none;
                border-radius: 100%;
                position: absolute;
                right: 20px;
                top: 84px;
                z-index: 2001;
                right: 10px;
                top: 78px;
                background-color: rgba(0, 0, 0, 0.16);

                &:hover {
                  cursor: pointer;
                }

                &::before {
                  content: "";
                  width: 16px;
                  height: 2px;
                  background: #ffffff;
                  border-radius: 20px;
                  left: 50%;
                  top: calc(50% - 5px);
                  display: block;
                  position: absolute;
                  margin-left: -8px;
                  -webkit-transition: all 300ms ease;
                  -moz-transition: all 300ms ease;
                  -ms-transition: all 300ms ease;
                  -o-transition: all 300ms ease;
                  transition: all 300ms ease;
                }

                &::after {
                  content: "";
                  width: 16px;
                  height: 2px;
                  background: #ffffff;
                  border-radius: 20px;
                  left: 50%;
                  bottom: calc(50% - 5px);
                  display: block;
                  position: absolute;
                  margin-left: -8px;
                  -webkit-transition: all 300ms ease;
                  -moz-transition: all 300ms ease;
                  -ms-transition: all 300ms ease;
                  -o-transition: all 300ms ease;
                  transition: all 300ms ease;
                }

                &::before {
                  transform: rotate(45deg);
                  top: calc(50% - 1px);
                }

                &:after {
                  transform: rotate(-45deg);
                  bottom: calc(50% - 1px);
                }
              }
            }

            .detailed_sidebar_scrollbar {
              display: flex;
              flex-direction: column;
              height: calc(100vh - 240px);
              overflow: auto;
              padding: 20px;
              overflow-x: hidden;
              // @media (min-width: 1600px) {
              //   height: calc(100vh - 220px);
              // }

              .sidebar_module_tab_wrapper {
                background-color: transparent;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                padding: 0;
                // webkit-transition: all 300ms ease;
                -moz-transition: all 300ms ease;
                -ms-transition: all 300ms ease;
                -o-transition: all 300ms ease;
                transition: all 300ms ease;

                .sidebar_module_tab {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  // padding: 15px 10px;
                  padding: 15px 20px;
                  border-radius: 7px;

                  .sidebar_module_tab_content {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    .sidebar_module_icon_wrapper {
                      width: auto;
                      height: 24px;
                      margin-right: 24px;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      .nav_link_icon {
                        height: 100%;
                        width: auto;
                        stroke: var(--gray50);
                      }
                    }

                    .sidebar_module_title {
                      font-size: 0.875rem;
                      line-height: 1.2;
                      font-weight: var(--font-weight300);
                      // padding: 20px;
                      color: var(--gray50);
                      letter-spacing: 0.8px;
                      margin-bottom: 0;
                      margin-right: 15px;
                    }
                  }

                  .plus {
                    position: relative;
                    width: 10px;
                    height: 10px;
                    background: transparent;

                    &:before,
                    &:after {
                      content: "";
                      position: absolute;
                      background: var(--gray50);
                    }

                    &:before {
                      left: 50%;
                      top: 0;
                      bottom: 0;
                      width: 1px;
                      transform: translateX(-50%);
                    }

                    &:after {
                      top: 50%;
                      left: 0px;
                      right: 0px;
                      height: 1px;
                      transform: translateY(-50%);
                    }
                  }

                  .minus {
                    position: relative;
                    width: 10px;
                    height: 10px;
                    background: transparent;

                    &:after {
                      content: "";
                      position: absolute;
                      background: var(--light);
                    }

                    &:after {
                      top: 50%;
                      left: 0px;
                      right: 0px;
                      height: 1px;
                      transform: translateY(-50%);
                    }
                  }

                  &:hover {
                    background-color: rgba(0, 0, 0, 0.2);
                    border-radius: 7px;
                  }
                }

                .sidebar_module_tab.active {
                  background-color: var(--light);
                  margin-bottom: 10px;

                  .sidebar_module_tab_content {
                    .sidebar_module_icon_wrapper {
                      .nav_link_icon {
                        stroke: var(--secondary-navy-blue);
                      }
                    }
                  }

                  .sidebar_module_title {
                    font-weight: var(--font-weight700);
                    color: var(--secondary-navy-blue);
                  }
                }

                .sidebar_submodules_wrapper {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-self: flex-start;

                  .sub_nav_link {
                    background-color: transparent;
                    display: flex;
                    flex-direction: row;
                    padding: 10px 30px;
                    text-decoration: none;

                    .sub_nav_link_icon_wrapper {
                      width: auto;
                      height: 25px;
                      margin-right: 20px;

                      .nav_link_icon {
                        stroke: var(--gray50);
                        height: 100%;
                      }
                    }

                    .sub_nav_link_title {
                      font-size: 0.875rem;
                      color: var(--gray50);
                      font-weight: var(--font-weight300);
                    }

                    &:hover {
                      background-color: rgba(0, 0, 0, 0.2);
                      border-radius: 7px;
                    }
                  }

                  .sub_nav_link.active {
                    background-color: rgba(0, 0, 0, 0.2);
                    border-radius: 7px;

                    .sub_nav_link_icon_wrapper {
                      .nav_link_icon {
                        stroke: var(--light);
                        height: 100%;
                      }
                    }

                    .sub_nav_link_title {
                      color: var(--light);
                      font-weight: var(--font-weight400);
                    }
                  }
                }
              }

              .sidebar_section_title {
                font-size: 16px;
                line-height: 1;
                font-weight: var(--font-weight300);
                padding: 20px;
                color: var(--gray50);
                letter-spacing: 0.8px;
                margin-bottom: 0;
              }

              .sidebar_nav {
                margin: 0 10px;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                &:last-child {
                  border-bottom: none;
                }

                .nav_link {
                  font-size: 14px;
                  font-weight: var(--font-weight500);
                  line-height: 1;
                  font-weight: normal;
                  color: var(--light);
                  text-decoration: none;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 15px 20px;
                  cursor: pointer;
                  -webkit-transition: all 200ms ease;
                  -moz-transition: all 200ms ease;
                  -ms-transition: all 200ms ease;
                  -o-transition: all 200ms ease;
                  transition: all 200ms ease;
                  border-radius: 7px;

                  .nav_link_icon {
                    stroke: var(--light);
                    height: 20px;
                    margin-right: 10px;
                  }

                  &:hover {
                    background-color: var(--light);
                    color: var(--secondary-navy-blue);

                    .nav_link_icon {
                      stroke: var(--secondary-navy-blue);
                    }
                  }
                }
              }
            }

            .detailed_sidebar_footer {
              display: flex;
              height: 140px;
              width: 100%;
              padding: 0 10px 10px;
              justify-content: flex-end;
              align-items: center;
              flex-direction: column;
              overflow-x: hidden;

              .profile_wrapper {
                height: inherit;
                width: inherit;
                background-color: var(--secondary-navy-blue);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                text-decoration: none;
                padding: 15px 20px;
                height: 54px;
                border-radius: 7px;

                .icon_wrapper {
                  height: 40px;
                  width: 40px;
                  border-radius: 100%;

                  .profile_icon {
                    width: 100%;
                    height: auto;
                    border-radius: 100%;
                  }
                }

                .profile_details {
                  padding-left: 10px;

                  .profile_title {
                    font-size: 16px;
                    line-height: 1;
                    color: var(--light);
                    font-weight: var(--font-weight500);
                    margin-bottom: 5px;
                  }

                  .profile_subtitle {
                    font-size: 12px;
                    line-height: 1;
                    color: var(--gray50);
                    font-weight: var(--font-weight400);
                  }
                }

                &:hover {
                  background-color: var(--light);

                  .profile_details {
                    .profile_title {
                      color: var(--secondary-navy-blue);
                    }

                    .profile_subtitle {
                      color: var(--secondary-navy-blue);
                    }
                  }
                }
              }

              .sidebar_nav {
                width: 100%;

                .nav_link {
                  font-size: 14px;
                  font-weight: var(--font-weight500);
                  line-height: 1;
                  font-weight: normal;
                  color: var(--light);
                  text-decoration: none;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 15px 20px;
                  cursor: pointer;
                  -webkit-transition: all 200ms ease;
                  -moz-transition: all 200ms ease;
                  -ms-transition: all 200ms ease;
                  -o-transition: all 200ms ease;
                  transition: all 200ms ease;
                  border-radius: 7px;

                  .nav_link_icon_wrapper {
                    width: auto;
                    height: 24px;
                    margin-right: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .nav_link_icon {
                      stroke: var(--light);
                      height: 100%;
                      width: auto;
                    }
                  }

                  &:hover {
                    background-color: var(--light);
                    color: var(--secondary-navy-blue);

                    .nav_link_icon {
                      stroke: var(--secondary-navy-blue);
                    }
                  }
                }
              }
            }

            // @media (max-width: 1600px) {
            //   display: none;
            // }
          }

          // @media (min-width: 1600px) {
          //   .icons_sidebar {
          //     display: none;
          //   }
          //   .detailed_sidebar {
          //     display: block;
          //   }
          // }
        }
      }
    }
  }

  // MAIN PAGE
  .main_page {
    width: 100%;
    max-width: calc(100% - 60px);
    margin: 0 auto;
    margin-left: 60px;
    margin-top: 70px;
    background-color: transparent;

    .content {
      .map_section {
        display: grid;
        grid-gap: 20px;
        grid-column: span 1 / span 1;
        background-color: var(--gray50);
      }

      .map_section+.content_section {
        grid-column: span 3 / span 3;
      }

      .content_section {
        position: relative;
        background-color: transparent;

        .page_content {
          padding: 0 20px 20px;

          @media (max-width: 768px) {
            padding: 0;
          }

          .dashboard_home {
            .page_footer {
              padding: 20px 0 0;
              line-height: 1.3;
              margin-bottom: 0;
              font-size: 14px;
              color: var(--secondary-gray600);
            }
          }
        }

        .page_header {
          padding: 20px;

          @media (max-width: 576px) {
            padding: 10px;
          }
        }

        .header_badges_wrapper {
          padding: 10px 20px 0;

          .badges_list_wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .badge_wrapper {
              .button_badge {
                background: #cedfe8 !important;

                &:hover {
                  cursor: auto !important;
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 1600px) {
      max-width: calc(100% - 340px);
      margin-left: 340px;
    }
  }

  .main_page.unauth_page {
    max-width: 100%;
    margin: 0;
    height: 100vh;
  }
}

// TABLE
.section_card {

  // .col_span_2 {
  //   .section_card_body {
  //     .table_filters_section {
  //       top: -5.7rem !important;
  //       z-index: 1 !important;
  //     }
  //   }
  // }
  // .table_filters_section {
  //   position: absolute;
  //   top: -4rem;
  //   right: 0;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-end;
  //   @media (max-width: 768px) {
  //     position: relative;
  //     padding-top: 0;
  //     padding-bottom: 0;
  //     .filters_wrapper {
  //       .filter {
  //         @media (max-width: 768px) {
  //           &:first-child {
  //             padding-top: 0;
  //             margin-top: -20px;
  //           }
  //         }
  //         @media (max-width: 576px) {
  //           &:first-child {
  //             padding-top: 0;
  //             margin-top: -10px;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   // .filters_wrapper {
  //   //   .filter {
  //   //     .dropdown {
  //   //     }
  //   //   }
  //   // }
  // }
  .table_wrapper {
    margin: 0 20px;
    overflow: auto;
    position: relative;

    // z-index: 1;
    .table {
      background-color: #fff;
      margin-bottom: 0;
      width: 100%;
      text-align: left;
      border-collapse: collapse;
      border-spacing: 0;

      thead {
        background-color: var(--secondary-navy-blue);

        th {
          .th_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .th_title {
              margin-right: 10px;
            }

            .icon_wrapper {
              width: auto;
              display: flex;
              flex-direction: row;
              align-items: center;

              .icon {
                height: 8px;
                width: auto;
                stroke: var(--gray50);
                opacity: 0.5;

                &:first-child {
                  margin-bottom: 2px;
                }

                &:last-child {
                  margin-top: 2px;
                }
              }

              .icon.active {
                opacity: 1;
                stroke: var(--light);
              }
            }
          }
        }

        th,
        td {
          font-size: 14px;
          font-weight: 600;
          color: var(--light);
          border: none;
          border-bottom: 0.5px solid var(--secondary-gray500);
          white-space: nowrap;
          vertical-align: middle;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }

        tr {
          &:last-child {
            border-bottom: none;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      tbody {
        tr {
          th {
            width: 5rem;
          }

          td.td_checkbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;

            .input_checkbox {
              min-width: 15px;
              max-width: 15px;
              max-width: 15px;
              max-height: 15px;
              width: 15px;
              height: 15px;
              display: flex;
              margin: 0 10px 0 0;
              padding: 0;
            }
          }

          td.text_align {
            text-align: start !important;
          }

          td.number_align {
            text-align: end !important;
          }

          td.no_data_align {
            text-align: center !important;
          }

          td {
            .cell_badge_wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;

              .badge_wrapper {
                margin-left: 10px;

                .badge {
                  opacity: 1;
                  width: 20px;
                  height: 20px;
                  font-size: 12px;
                  border-radius: 7px;
                  border: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  &:hover {
                    cursor: default;
                  }
                }

                .badge.positive {
                  background-color: rgba(7, 193, 128, 0.7);
                  color: rgba(255, 255, 255, 1);
                }

                .badge.negative {
                  background-color: rgba(213, 31, 48, 0.7);
                  color: rgba(255, 255, 255, 1);
                }
              }
            }
          }
        }
      }

      th,
      td {
        padding: 10px 20px;

        .td_details_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .title {
            font-size: 20px;
            font-weight: var(--font-weight500);
            color: var(--secondary-gray700);
            line-height: 1.2;
            margin-bottom: 5px;
            word-wrap: break-word;
            word-break: break-all;
            white-space: break-spaces;
          }

          .sub_title {
            font-size: 14px;
            font-weight: var(--font-weight400);
            color: var(--secondary-gray500);
            line-height: 1;
          }
        }

        &:first-child {
          .td_details_wrapper {
            align-items: flex-start;
          }
        }

        &:last-child {
          .td_details_wrapper {
            align-items: flex-end;
          }
        }
      }

      th:first-letter,
      td:first-letter {
        text-transform: capitalize;
      }
    }

    .table.table_bordered {
      border: none;

      tbody {
        tr {
          border-bottom: 0.5px solid var(--primary-hover200);

          th {
            font-weight: 500;
          }

          td,
          th {
            border-bottom: none !important;
            font-size: 14px;
            line-height: 24px;
            word-wrap: break-word;
            border-collapse: collapse;
            box-sizing: border-box;
            position: relative;
            width: 100%;
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
            padding: 1.25rem;
            color: #333;
            font-weight: 400;
            border: none;
            vertical-align: middle;
            border-bottom: none !important;
            border-bottom: 0.5px solid var(--primary-hover200);
            white-space: nowrap;

            &:first-child {
              padding-left: 20px;
            }

            &:last-child {
              padding-right: 20px;
            }
          }

          td {
            width: auto !important;
            padding: 10px 20px;
            line-height: 1;
            border: none;
            vertical-align: middle;
            border-bottom: none !important;
            color: var(--gray700);
            text-align: end;

            &:first-child {
              text-align: start;
            }
          }

          &:hover {
            background-color: var(--primary-hover200);
          }

          &:last-child {
            border-bottom-color: transparent;
          }
        }

        tr.active {
          background-color: var(--primary-hover200);
        }

        tr.noactive,
        tr.active {
          &:hover {
            cursor: pointer;
          }
        }

        .table_profile_object {
          display: flex;
          flex-direction: row;

          .table_profile_img {
            height: 50px;
            width: 50px;
            border-radius: 10px;
            margin-right: 15px;
          }

          .table_profile_body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .table_profile_title {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 5px;
              line-height: 1;
            }

            .table_profile_subtitle {
              font-size: 14px;
              color: var(--gray600);
              line-height: 1;
            }
          }
        }

        // FOR BADGES IN TABLE
        .badges_list_wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0;
          height: 100%;
          max-width: 200px;

          .badge_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .button_badge {
              background: #cedfe8 !important;
              max-width: 200px;
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;

              &:hover {
                cursor: auto !important;
              }
            }
          }
        }

        // F0R FLAG IMAGES IN TABLE
        .country_flag_section {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .country_flag_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 40px;
            width: 100%;
            width: 40px;
            height: 25px;
            margin-right: 10px;

            .flag_img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    // .table.table_striped {
    //   tbody {
    //     tr {
    //       &:nth-child(even) {
    //         background-color: rgba(154, 220, 251, 0.2);
    //         border-bottom: 2px solid rgba(154, 220, 251, 0.3) !important;
    //         border-top: 2px solid rgba(154, 220, 251, 0.3) !important;
    //       }

    //       &:nth-child(odd) {
    //         background-color: rgba(255, 255, 255, 1);
    //       }
    //     }
    //   }
    // }

    @media (max-width: 576px) {
      padding: 10px;
    }
  }

  .table_wrapper.section_card_table_wrapper {
    height: 100%;
    max-height: 250px;
    padding: 0;

    .table {
      tbody {
        tr {
          &:last-child {
            border-bottom: none;
          }
        }

        td.bold {
          font-weight: var(--font-weight500);
        }

        td.span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-right: 5px;

          .subtitle {
            font-size: var(--font-size-small);
            font-weight: var(--font-weight400);
          }
        }
      }
    }
  }

  .table_wrapper+.footer {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    @media (max-width: 576px) {
      flex-wrap: wrap;
    }

    .pagination {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      grid-column-gap: 10px !important;

      .btn_wrapper {
        padding: 0;
        width: auto;

        .theme_button {
          padding: 5px 10px;
          width: auto;
          height: 32px;
        }
      }

      @media (max-width: 576px) {
        flex-direction: column;

        .btn_wrapper {
          .theme_button {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 576px) {
      padding: 10px;
    }
  }

  @media (max-width: 768px) {
    border-radius: 0;
  }
}

// CAMPAIGN DROPDOWN
.dropdownWrapper {
  width: 100%;
  height: 350px;
  background-color: red;
  border-radius: 7px;
  display: flex;
  flex-direction: row;

  .tabsContainer {
    width: 300px;
    height: 100%;
    background-color: yellow;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;

    .tab {
      &:hover {
        cursor: pointer;
      }
    }

    .tab.active {
      background-color: #000;
      color: #fff;
    }
  }

  .optionsContainer {
    width: 100%;
    height: 100%;
    background-color: orange;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow-y: scroll;

    .optionsTab {
      width: 100%;
      display: flex;
      flex-direction: row;

      input {
        width: 20px;
        height: 20px;
      }
    }

    .optionsPlatformTabs {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;

      .platformTabs {
        width: 300px;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #038673;

        .tab {
          &:hover {
            cursor: pointer;
          }
        }

        .tab.active {
          background-color: #0c7dd9;
          color: #ffffff;
        }
      }

      .objectivesSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow: scroll;
        background-color: #07c180;
      }
    }
  }
}

.right_side.col_span_3 {
  margin-left: -20px;
}

.left_drawer {
  background-color: #ffffff;
  height: 100%;
  // padding: 20px;
  width: 25%;
  position: fixed;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(215, 170, 226, 0.23);
}

.right_side {
  width: 70%;
  position: absolute;
  right: 0px;
}

.p-0 {
  padding: 0px !important;
}

.p_10 {
  padding: 10px !important;
}

.w100 {
  width: 100%;
}

.card_header_title {
  font-size: 14px;
  font-weight: var(--font-weight400);
  color: var(--secondary-navy-blue);
  line-height: 1.4;
  text-align: start;
  margin-right: 0;
  // margin-bottom: 20px;
}

.brand_information {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.grp_item {
  display: flex;
  align-items: end;
}

.brand_data {
  font-size: 18px;
  font-weight: var(--font-weight400);
  color: var(--secondary-gray700);
  line-height: 1.2;
  text-align: left;
  margin-right: 0;
}

.brand_legend {
  font-size: 11px;
  font-weight: var(--font-weight500);
  color: var(--secondary-gray500);
  line-height: 1;
  text-align: left;
  margin-top: 0;
  display: block;
}

.brand_card_icon {
  stroke: var(--secondary-gray700);
  height: 20px;
}

.brand_icon {
  height: 28px;
  margin-right: 5px;
}

.card_map_title {
  display: flex;
}

.riCompare {
  .card_wrapper {
    .card.postCard {
      .card_header {
        .checkbox_wrapper {
          .checkbox_input {
            border: 1px solid var(--primary500);
          }
        }
      }

      .card_body {
        justify-content: center;

        .left_Section {
          .post_img_section {
            width: 100px;
            height: 100px;
            border-radius: 7px;
            padding: 5px;

            .img_wrapper {
              img {
                border-radius: 7px;
              }
            }
          }

          .brand_information {
            .brand_data {
              font-weight: var(--font-weight500);
              font-size: 20px;

              .brand_legend {
                margin-top: 0.2em;
                font-size: 12px;
              }
            }
          }

          .card_header_title {
            font-size: 16px;
            font-weight: var(--font-weight500);
            text-align: center;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .section_card {
    .table_wrapper {
      .table.table_bordered {
        tr {
          td {
            em {
              font-style: normal;
            }

            em.bold {
              color: var(--secondary-navy-blue);
            }
          }
        }
      }
    }
  }
}

.icz-rilist-inline {
  padding-left: 0;
  list-style: none;
  text-align: center;
  margin: 0 0 10px 0;

  li {
    display: inline-flex;
    padding: 5px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: var(--secondary-navy-blue);

    .legend-circle {
      width: 13px;
      height: 13px;
      border-radius: 100%;
      background: red;
      margin-right: 5px;
    }

    .icz-rilist-bullet1 {
      background: var(--primary500);
    }

    .icz-rilist-bullet2 {
      background: var(--primary400);
    }

    .icz-rilist-bullet3 {
      background: #70c6fe;
    }

    .icz-rilist-bullet4 {
      background: #9fd9fe;
    }

    .circleRed {
      background: var(--secondary-red600);
      border-radius: 100%;
    }

    .circleGreen {
      background: var(--secondary-green600);
      border-radius: 100%;
    }

    .circleOrange {
      background: var(--secondary-yellow600);
      border-radius: 100%;
    }
  }
}

.mapRightWrapper {
  .mapHeaderWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    background-color: #fff;

    .mapHeader {
      padding: 10px 20px 0 20px;
    }
  }

  .mapBoxContainer {
    flex: 0 0 auto;
    margin: 0 auto;
    height: calc(100vh - 104px - 180px - 40px);

    .section_card_map_wrapper {
      height: calc(100vh - 104px - 180px - 40px);
      max-height: inherit;

      .map_section {
        max-height: inherit;
      }
    }
  }

  .mapLegendsContainer {
    height: 180px;
  }
}

.text_center {
  text-align: center;
}

.single_postCard_btn,
.smallBtn {
  .theme_button.secondary_btn {
    width: 50%;
    margin-left: auto;
    height: 35px;
  }
}

.text_right {
  text-align: right;
}

.show_allPost.card_wrapper {
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
}

.compare_results_cards {
  .table.table_striped.table_bordered {
    .tableCard_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .tableCard_img {
        width: 270px;
        height: 240px;
        border-radius: 10px;
      }

      .tableCard_text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin: 20px auto;
        font-size: 12px;
      }
    }

    tbody {
      tr {
        &:first-child {
          border-bottom: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .card_border_right {
    &::after {
      height: 90%;
      top: 5%;
    }
  }
}

.swiper-wrapper {
  .postCard {
    .time_wrapper {
      padding-left: 15px;
    }
  }
}

.card_border_top {
  position: relative;
  padding-top: 30px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0px;
    background-color: #cacaca;
  }
}

.gray_title {
  color: var(--secondary-gray700);
}

.gray_subTitle {
  color: var(--secondary-gray500) !important;
}

.view_all_cards {
  .post_details_section {
    table {
      tbody {
        td {
          padding: 10px !important;

          h3 {
            padding-bottom: 3px;
          }
        }
      }
    }
  }

  .button_wrapper {
    .theme_button {
      margin-top: 10px;
    }
  }
}

.left_drawer.col_span_1.fixed-left-grid {
  position: sticky;
  top: 70px;
  height: calc(100vh - 70px);
  width: unset;
  grid-column: unset;
}

.grid.drawer_grid {
  grid-template-columns: 25% 1fr;
  overflow: unset;
}

.right_side.col_span_3.relative-right-grid {
  position: unset;
  right: unset;
  width: unset;
  grid-column: unset;
}

.brand-comp-table img {
  width: 100px;
  border-radius: 7px;
}

.section_card .section_card_header .filters_wrapper .filter:nth-child(2) {
  // margin-right: -10px;
  // width: 175px;
  margin-right: 0;
}

.searchbar_wrap .section_card .section_card_header .filters_wrapper .filter:nth-child(2) .css-t1s8vm-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.section_card .section_card_header .filters_wrapper .filter.filter2 input {
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.filter.filter2 input {
  height: 40px;
}

.section_card_header.map_breadcrumb_header {
  display: flex;
  justify-content: flex-start;
  color: var(--primary500);
  text-transform: uppercase;
  font-weight: 600;
  flex-wrap: wrap;

  // white-space: nowrap;
  &:last-child {
    color: var(--gray600);
  }
}

.section_card_header.map_breadcrumb_header>* {
  padding-right: 6px;
  cursor: pointer;
}

// .section_card_header.map_breadcrumb_header:last-child {
//   padding-left: 5px;
//   color: var(--gray600);
// }
.test {
  display: flex;
  flex-direction: row;

  .brands_section {
    background-color: yellow;
    width: 33%;

    .active {
      background-color: red;
    }
  }

  .campaigns_section {
    background-color: orange;
    width: 33%;

    .active {
      background-color: red;
    }
  }

  .ads_section {
    background-color: #07c180;
    width: 34%;
    overflow: auto;

    .active {
      background-color: red;
    }
  }
}

.fileUpload {
  position: relative;
}

.upload-btn {
  width: 44px;
}

form.fileUpload.flex {
  margin-bottom: 2rem;
}

input#csvFileInput {
  margin-right: 1rem;
}

.button-upload {
  width: auto;
  padding: 0 1rem;
}

.import-btn {
  margin-right: 1rem;
}

.file-button-section button svg,
.file-button-section a svg {
  width: 30px;
  stroke: var(--light);
}

.file-button-section button svg g path,
.file-button-section a svg g path {
  stroke: var(--light);
}

.treemap_breadcrumb {
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 600;
  color: var(--primary500);
  margin-top: 10px;

  span {
    &:last-child {
      color: var(--gray600);
    }
  }
}

.loader_wrapper_new {
  text-align: center;
  min-height: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loader-wrap {
  width: 100%;
}

.loader img {
  width: auto;
  margin: 0 auto;
  max-width: 100%;
}

.loader-copy {
  padding: 1rem;
}

.grid {
  position: relative;
}

.loader {
  font-family: revert;
  color: var(--dark);
  height: 100%;
  width: 100%;
  display: flex;
  min-height: 300px;
  align-items: center;
  position: relative;
  justify-content: center;
}

.loader-img {
  /*   border: 1px solid var(--gray300); */
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.loader-img.grayed {

  /* filter: grayscale(1); */
  img {
    opacity: 0.7;
  }
}

.loader-info {
  font-family: revert;
  color: var(--secondary-gray700);
  margin-top: 10px;
}

.info1 {
  color: var(--secondary-gray700);
  line-height: 1.2;
  font-weight: 500;
  margin: 0;
}

.info1~p:last-child {
  line-height: 1.3;
  margin-bottom: 0;
  font-size: 14px;
  color: var(--secondary-gray600);
}

.page_content {
  position: relative;
}

.loader-info2 {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .loader-wrap {
    position: relative;
    min-height: 300px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
  }

  .loader_wrapper_new {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
    transform: unset;
    text-align: center;
  }
}

.card_wrapper {
  .card.postCard.brandCompareWrapper {
    .postBrandHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 5px 0;
      min-height: 45px;

      .card_header_title {
        font-weight: var(--font-weight500);
        font-size: 16px;
        color: var(--secondary-navy-blue);
        margin-right: 10px;
      }
    }

    .card_body {
      .left_Section {
        width: 100%;
        flex-direction: row;

        .post_img_section {
          .img_wrapper {
            border-radius: 7px;
          }
        }

        .caption_wrapper {
          width: 100%;

          .brand_information {
            flex-direction: column;

            .grp_item {
              border-bottom: 1px solid var(--gray300);
              width: 90%;
              padding-bottom: 5px;
            }

            :nth-child(2) {
              padding-top: 5px;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.searchbar_wrap {
  position: relative;
  display: flex;

  .form_dropdown {
    max-width: 175px;

    .css-b62m3t-container {
      .css-t1s8vm-control {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-right-color: transparent;

        .css-blhudp-control:hover {
          border-color: none !important;
        }

        .css-blhudp-control {
          box-shadow: none !important;
        }
      }
    }
  }

  .form_control {
    padding-right: 40px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;

    &:focus {
      background-color: var(--light);
    }
  }

  .search {
    background-color: var(--light);
  }

  svg.search_icon {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 18px;
    height: 30px;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
      stroke: var(--primary500);
    }
  }

  .clear_input {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    top: 1px;
    right: 30px;
    background-color: var(--light);
    color: var(--secondary-gray600);

    &:hover {
      cursor: pointer;
      color: var(--primary500);
    }
  }
}

.toast_card {
  background-color: #fff;
  border: 1px solid var(--gray300);
  padding: 10px;
  border-radius: 7px;
  margin-top: 1.5rem;
  margin-bottom: 0px;

  .col_span_1 {
    position: relative;

    &:not(:last-child):before {
      position: absolute;
      content: "";
      width: 1px;
      height: 95%;
      top: 0px;
      right: 5px;
      background-color: var(--gray600);
    }
  }
}

.toast_card_wrap {
  display: flex;
  align-items: flex-end;
  min-height: 0px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid var(--gray300);
  border-radius: 8px;

  .fileUpload.flex {
    margin-bottom: 0px;
  }
}

.btn_wrapper.influencerBt {
  .secondary_btn {
    padding: 0 15px;
    text-transform: uppercase;
  }

  .primary_btn {
    padding: 0 15px;
    text-transform: uppercase;
  }
}

.searchbar_wrap .form_dropdown .css-b62m3t-container .css-t1s8vm-control:hover {
  border-bottom-right-radius: 0;
  border-right-color: transparent;
  border-top-right-radius: 0;
}

#market-kpi-score {
  .section_card_body {
    position: relative;

    .solid_gauge_content {
      position: absolute;
      left: 40px;
      top: 90px;
      text-align: center;

      h1 {
        color: #0c7dd9;
        font-size: 40px;
      }

      aside {
        font-weight: 600;
        line-height: 2.5;
      }
    }
  }
}

// RI SALES
.ri_sales_chart_tabs_header_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .tab {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-bottom: 4px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    .header {
      .score_title {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
        color: var(--primary500);
      }
    }

    .section_card_chart_wrapper {
      width: 100%;
      height: 180px;
      padding: 20px;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;

      .section_card_chart {
        width: 100%;
        height: 100%;
      }
    }

    .footer {
      .score_subtitle {
        font-size: 1rem;
        font-weight: 500;
        color: var(--secondary-gray500);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .tab.active {
    background-color: transparent;
    border-color: var(--primary500);
    border-right-color: #eff2f7;
    border-left-color: #eff2f7;

    .footer {
      .score_subtitle {
        color: var(--secondary-navy-blue);
      }
    }

    // .active_arrow {
    //   width: 0;
    //   height: 0;
    //   border-left: 10px solid transparent;
    //   border-right: 10px solid transparent;
    //   border-top: 10px solid var(--primary500);
    //   position: absolute;
    //   bottom: 0;
    //   right: calc(50% - 10px);
    //   z-index: 999999 !important;
    //   margin: auto;
    // }
  }

  .default_tab.tab {
    position: relative;

    .title_wrapper {
      position: absolute;
      top: 40px;
      left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .score_title {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1;
        color: var(--primary500);
      }

      .score_subtitle {
        font-size: 1rem;
        font-weight: 500;
        color: var(--secondary-navy-blue);
      }
    }

    .section_card_chart_wrapper {
      width: 100%;
      height: 250px;
      padding: 20px;
      padding-bottom: 0;
      position: relative;

      .section_card_chart {
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      cursor: auto;
    }
  }
}

.ri_sales_chart_tabs_content_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;

  .header {
    .score_title {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1;
      color: var(--primary500);
    }
  }

  .section_card_chart_wrapper {
    width: 100%;
    height: 180px;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    .section_card_chart {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    .score_subtitle {
      font-size: 1rem;
      font-weight: 500;
      color: var(--secondary-gray500);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

div[data-html2canvas-ignore] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.grid.cols_span_1 {
  div[data-html2canvas-ignore] {
    width: 100%;
  }
}

.export_dropdown_wrapper {
  margin-left: 1rem;
}

.wrapped-table td {
  padding: 10px 5px !important;
  font-size: 14px !important;
}

.grid.grid_cols_1.card_border_right:nth-child(3n):after {
  background: transparent;
}

.grid.cols_span_1 div[data-html2canvas-ignore],
.grid.col_span_1 div[data-html2canvas-ignore] {
  width: 100%;
}

.mr_20 {
  margin-right: 20px;
}

.position-relative {
  position: relative;
}

// REMOVE THIS CSS TO SHOW "DOWNLOAD CSV" IN HEADER DD
.dashboard_navbar .export_dropdown_wrapper .options_wrapper .csv_option {
  display: none;
}

// .filter-export {
//   width: 90px;
//   display: flex;
//   justify-content: end;
// }

.mapHeader div[data-html2canvas-ignore] {
  width: 100%;
}

.table_filters_section .filters_wrapper div[data-html2canvas-ignore] {
  width: 100%;

  .filter-export {
    margin-left: 10px;
  }
}

.coming_soon {
  color: var(--secondary-gray600);
}

body {
  overflow-x: hidden;
}

// SKELETONS FOR SINGLE CARD TITLES
.skeleton_card_body_wrapper {
  .title_skeleton_loader_wrapper {
    height: 22px;
    width: 100%;
    background-color: #dddbdd;
    position: relative;

    .title_skeleton_loader {
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 30%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0));
        -webkit-animation: shimmer 2s infinite;
        animation: shimmer 2s infinite;
        content: "";
      }
    }
  }

  .subtitle_skeleton_loader_wrapper {
    height: 17px;
    width: 100%;
    background-color: #dddbdd;
    position: relative;

    .subtitle_skeleton_loader {
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 30%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0));
        -webkit-animation: shimmer 2s infinite;
        animation: shimmer 2s infinite;
        content: "";
      }
    }
  }
}

.skeleton_card_body_wrapper.compare {
  .value_row.skeleton {
    height: 21.59px;
    width: 100%;
    background-color: #dddbdd;
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 30%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0));
      -webkit-animation: shimmer 2s infinite;
      animation: shimmer 2s infinite;
      content: "";
    }
  }

  .title_row.skeleton {
    height: 26.39px;
    width: 100%;
    background-color: #dddbdd;
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 30%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0));
      -webkit-animation: shimmer 2s infinite;
      animation: shimmer 2s infinite;
      content: "";
    }
  }

  .info_wrapper {
    background: rgba(255, 255, 255, 0.7);
    height: 50px;
    width: auto;
    padding: 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    opacity: 0.5;

    .info1 {
      color: var(--secondary-gray700);
      line-height: 1.2;
      font-weight: 500;
      margin: 0;
    }
  }
}

// ERROR STATE FOR SINGLE CARD
.error_card_body_wrapper {
  height: 169px !important;

  .info_wrapper {
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    width: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .info1 {
      color: var(--secondary-gray700);
      line-height: 1.2;
      font-weight: 500;
      margin: 0;
    }
  }
}

// SKELETON LOADER FOR SINGLE TABLE CELL
.table_cell_skeleton_wrapper {
  height: 1rem;
  width: 100%;
  background-color: #dddbdd;
  position: relative;

  .table_cell_skeleton {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 30%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0));
      -webkit-animation: shimmer 2s infinite;
      animation: shimmer 2s infinite;
      content: "";
    }
  }
}

.error_cell_wrapper {
  height: 1rem !important;

  .info_wrapper {
    background: transparent;
    height: 100%;
    width: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    opacity: 0.5;

    .info1 {
      color: var(--secondary-gray700);
      line-height: 1.2;
      font-weight: 500;
      margin: 0;
    }
  }
}

.section_card_chart_wrapper .skeleton_wrapper {
  width: 100%;
  height: 350px;
  position: relative;
}

.skeleton_wrapper,
.skeleton_map_wrapper,
.hash_wrapper {
  height: 100%;
  width: 100%;
}

.loader_img_wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

.hash_wrapper,
.section_card_img_wrapper .loader_img_wrapper,
.table_skeleton_loader {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.section_header.slider_header.skeleton_header {
  display: none !important;
}

.grid.col_span_1>.skeleton_wrapper {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.section_card_chart_wrapper,
.table_wrapper {
  .skeleton_wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.table_wrapper {
  .skeleton_wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.skeleton_wrapper {
  .section_card_chart {
    width: 100%;
    height: 100%;
  }

  tr {
    border: 1px solid #000 !important;

    td {
      padding: 0 !important;
      border: 10px solid #fff !important;
      line-height: 1.4 !important;
      position: relative;
    }
  }

  .card {
    overflow: hidden;
    position: relative;
  }

  .card_wrapper .card .card_new_footer {
    width: calc(100% - 40px);
    height: 100px;
    margin-top: -35px;
    padding-bottom: 20px;
    margin-left: 20px;
  }

  .card_growth_section .text_section.positive_growth,
  .icon_section,
  .thumbnailImg {
    visibility: hidden;
  }

  .slider_header,
  .curr_value,
  .prev_value,
  .value_title,
  .card_growth_section,
  .card_header_title,
  .value_row,
  .title_row,
  .metrics_card_chart_new_wrapper,
  .section_card_chart,
  td,
  .skeleton_map_wrapper,
  .hash_wrapper,
  .videoThumbnailCard {
    background-color: #dddbdd !important;
    color: #dddbdd !important;
  }

  .card .card.videoThumbnailCard {
    margin-bottom: 0;
  }
}

.skeleton_wrapper {

  .slider_header,
  .card_growth_section,
  .card_header_title,
  .metrics_card_chart_new_wrapper,
  .section_card_chart,
  td,
  .skeleton_map_wrapper,
  .hash_wrapper,
  .videoThumbnailCard {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.2) 30%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0));
      -webkit-animation: shimmer 2s infinite;
      animation: shimmer 2s infinite;
      content: "";
    }
  }
}

/* .skeleton_wrapper {
  .slider_header,
  .curr_value,
  .prev_value,
  .value_title,
  .card_growth_section,
  .card_header_title,
  .value_row,
  .title_row,
  .metrics_card_chart_new_wrapper,
  .section_card_chart,
  td,
  .skeleton_map_wrapper,
  .hash_wrapper,
  .videoThumbnailCard {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 30%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
      -webkit-animation: shimmer 2s infinite;
      animation: shimmer 2s infinite;
      content: "";
    }
  }
} 
 */

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.section_card_chart .loader-chart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.section_card_chart picture {
  display: block;
  opacity: 0.4;
}

.top_post_export {
  div[data-html2canvas-ignore] {
    width: auto;
  }

  .export_dropdown_wrapper {
    margin-left: 0rem;
  }
}

.compare_loader_height {
  height: 350px;
}

.dashboard_container .dashboard_sidebar .sidebar .module_sidebar .sidebar_wrapper .detailed_sidebar.preclosed {
  left: -340px;
}

.dashboard_container .dashboard_sidebar .sidebar .module_sidebar .sidebar_wrapper .detailed_sidebar.closed {
  left: -340px;
  transition: left 0.4s ease-in-out;
}

.dashboard_container .dashboard_sidebar .sidebar .module_sidebar .sidebar_wrapper .detailed_sidebar.opened {
  left: 0px;
  transition: left 0.4s ease-in-out;
}

.compare_sec {
  scroll-margin: 100px;
}

.flex-align-center {
  align-items: center;
}

// CUSTOM LEGENDS
.max_chart_height {
  max-height: 300px;
}

.legends_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 50px;
  overflow-y: auto;
  background-color: transparent;

  .legend {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .legend_circle {
      width: 13px;
      height: 13px;
      // background-color: yellow;
      border-radius: 100%;
      margin-right: 5px;
    }

    .legend_title {
      font-size: 12px;
      font-weight: 400;
      color: #213345;
      margin-right: 5px;
    }

    .legend_value {
      font-size: 12px;
      font-weight: 400;
      color: var(--gray600);
    }
  }
}

.compare_sec {
  scroll-margin: 100px;
}

.flex-align-center {
  align-items: center;
}

// CUSTOM LEGENDS
.max_chart_height {
  max-height: 300px;
}

.legends_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 50px;
  overflow-y: auto;
  background-color: transparent;

  .legend {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .legend_circle {
      width: 13px;
      height: 13px;
      // background-color: yellow;
      border-radius: 100%;
      margin-right: 5px;
    }

    .legend_title {
      font-size: 12px;
      font-weight: 400;
      color: #213345;
      margin-right: 5px;
    }

    .legend_value {
      font-size: 12px;
      font-weight: 400;
      color: var(--gray600);
    }
  }
}

.section_card .table_filters_section~.table_wrapper .table thead th:first-child,
.section_card .table_filters_section~.table_wrapper .table thead td:first-child {
  padding-left: 20px;
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: var(--secondary-navy-blue);
  /* box-shadow: -1px 0px 0 var(--primary-hover200) inset;	 */
}

.section_card .table_filters_section~.table_wrapper .table.table_bordered tbody tr td:first-child {
  position: sticky;
  left: 0;
  background: #fff;
  z-index: 3;
  /* box-shadow: -1px 0px 0 var(--primary-hover200) inset;	 */
}

.section_card .table_wrapper .table.table_bordered tbody tr {
  background: #fff;
}

.section_card .table_wrapper .table.table_bordered tbody tr:hover,
.section_card .table_wrapper .table.table_bordered tbody tr:hover td:first-child {
  background-color: #cfecff;
}

.grid.col_span_1 .grid.grid_cols_1 {
  width: 100%;
}

.know-more-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  padding: 1rem;
  padding-top: 0;
  overflow: hidden;
}

.know-more-footer .btn-small {
  font-size: 13px;
  padding: 7px 10px !important;
  background: #11aaea;
  border-radius: 5px;
  color: #fff !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  display: inline-block;
  outline: none;
  border: none;
  line-height: 1.8;
  margin-left: 20px;
  cursor: pointer;
}

.know-more-footer .btn-small-secondary {
  font-size: 13px;
  padding: 5px 10px !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  display: inline-block;
  outline: none;
  line-height: 1.8;
  margin-left: 20px;
  cursor: pointer;
  box-shadow: none;
  border: 2px solid;
  border-radius: 5px;
}

/* .section_card .section_card_header .section_dropdown.small_dropdown {
    max-width: 100px;
    margin-left:15px;
} */
.chart_details_wrapper {
  background-color: transparent;
  height: 100%;
  max-height: 350px;

  .main_wrapper {
    background-color: transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .body {
      padding-bottom: 10px;
      height: 100%;
      max-height: 290px;
      overflow: auto;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid hsla(0, 0%, 44%, 0.1);
        padding: 10px 20px;

        .title_wrapper {
          color: #486f88;
          // font-size: 20px;
          // font-weight: 500;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.2;
          width: 100%;
          height: 100%;
          background-color: transparent;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .value_wrapper {
          color: #486f88;
          // font-size: 20px;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.2;
          width: 100%;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-direction: column;
          position: relative;

          .metrics_wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            // justify-content: space-between;
            // align-items: center;
            // width: 100%;
            background-color: transparent;
            padding-top: 5px;

            .title_wrapper {
              color: #7d9eb5;
              font-size: 12px;
              font-weight: 500;
              line-height: 1.4;
              white-space: nowrap;
            }

            .value_wrapper {
              color: #7d9eb5;
              font-size: 12px;
              font-weight: 500;
              line-height: 1.4;
              margin-left: 2px;
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      background-color: var(--light);
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 2px solid hsla(0, 0%, 44%, 0.1);

      .title_wrapper {
        color: #486f88;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
      }

      .value_wrapper {
        color: #213345;
        font-size: 27px;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }
}

.grid_row_gap_0 {
  grid-row-gap: 0;
}

.grid_row_gap_0 .section_card_chart_wrapper {
  padding-bottom: 0 !important;
}

.section_card_header .flex.column.align_start {
  width: 100%;
}

.section_card .section_card_header.column_heading {
  flex-direction: column;
}

.section_card .section_card_header.column_heading .section_card_title {
  margin-bottom: 20px;
}

.cityabb {
  position: absolute;
  font-weight: 700;
  text-transform: uppercase;
}

.section_info {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
}

.section_card_title {
  white-space: nowrap;
}

.section_badge {
  margin-left: 15px;
}

.section_info {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
}

.section_card_title {
  white-space: nowrap;
}

.section_badge {
  margin-left: 15px;
}

.mapHeader .badge:hover+.badge_hover {
  right: -40px;
}

.section_card_title {
  width: min-content;
}

.badges_list_wrapper {
  .loader {
    height: 100px;
    min-height: 100px;
  }

  .loader-chart {
    height: 100%;
  }

  .loader img {
    width: auto;
    margin: 0 auto;
    max-width: unset;
    height: 100%;
  }

  .hash_wrapper {
    height: 100px;

    .section_card_chart picture {
      display: block;
      opacity: 0.4;
      height: 100%;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}

.w_50 {
  width: 50% !important;
}

.w_unset {
  width: unset !important;
  white-space: unset !important;
}

.flex_start {
  text-align: left;
}

.section_card_header_orm .section_card_title {
  width: 100%;
  /* width: calc(100% - 50px); */
  /* background: #fff000; */
  /* flex-wrap: nowrap; */
  /* overflow: auto;*/
  white-space: break-spaces;
}

.section_card_header_orm .section_info {
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 50px);
}

.section_card_header_orm div[data-html2canvas-ignore] {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /*   background: #ff0000; */
}

.section_card_body.bigger_card_body .section_card_chart_wrapper {
  height: 500px;
}

.section_card .section_card_body.bigger_card_body .chart_details_wrapper {
  background-color: transparent;
  height: 500px;
  max-height: 100%;
  min-height: 350px;
}

.section_card .section_card_body.bigger_card_body .chart_details_wrapper .main_wrapper .body {
  padding-bottom: 10px;
  height: 100%;
  max-height: 90%;
  overflow: auto;
}

.pl_50 {
  padding-left: 50px;
}

.admin_alert_wrap {
  position: fixed;
  left: 50%;
  right: 0;
  z-index: 99999999;
  transform: translate(-50%, -50%);
  transition: 300ms all ease-in-out;
}

.admin_alert_box {
  padding: 15px;
  padding-left: 10px;
  border: 1px solid transparent;
  width: fit-content;
  border-radius: 3px;
  // border-left: 5px solid #1c1c1c;
  box-shadow: 0 3px 10px rgba(215, 170, 226, 0.23);
  position: relative;

  .alert_title {
    font-size: 15px;
    line-height: 1.7;
    font-weight: 500;
  }

  .alert_body {
    font-size: 12px;
    line-height: 1.3;
    color: var(--gray600);
  }

  .close_button {
    background: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 6px;
  }

  .alert_main_wrap {
    display: flex;
    align-items: center;

    .check_icon_wrap {
      padding-right: 15px;

      .alert_check_icon {
        display: flex;
      }
    }
  }
}

.admin_alert_wrap.show {
  top: 135px;
}

.admin_alert_wrap.hide {
  top: -500px;
}

.admin_alert_box.success {
  background-color: #d1e7dd;
  // border-left: 5px solid #0a3622;

  .alert_title {
    color: var(--secondary-green700);
  }

  .alert_body {
    color: var(--secondary-green700);
    opacity: 0.8;
  }

  .close_button {
    color: var(--secondary-green700);
    opacity: 0.8;
  }

  .alert_check_icon {
    path {
      fill: var(--secondary-green700);
    }
  }
}

.admin_alert_box.danger {
  background-color: #e9d9da;
  // border-left: 5px solid #0a3622;

  .alert_title {
    color: var(--secondary-red700);
  }

  .alert_body {
    color: var(--secondary-red700);
    opacity: 0.8;
  }

  .close_button {
    color: var(--secondary-red700);
    opacity: 0.8;
  }

  .alert_check_icon {
    path {
      fill: var(--secondary-red700);
    }
  }
}

.section_card_header.long_header {
  flex-wrap: wrap;
}

.section_info.long_section_info {
  width: 50%;

  .section_card_title {
    width: 100%;

    white-space: unset;
  }
}

.section_card_header.long_header div[data-html2canvas-ignore] {
  width: 50%;
}

@media screen and (max-width: 1200px) {
  .section_card_header.long_header {
    flex-direction: column;
  }

  .section_info.long_section_info {
    width: 100%;

    .section_card_title {
      margin-bottom: 20px;
    }
  }

  .section_card_header.long_header div[data-html2canvas-ignore] {
    width: 100%;
  }
}

.market_compare_table.hide_rows {
  tr {
    display: none;
  }

  tr:nth-child(-n + 4) {
    display: table-row;
  }

  tr:nth-child(4) {
    border: none;
  }
}

.table_wrapper .market_compare_table {
  width: 100%;
  // padding: 10px 0;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid var(--gray200);
    padding: 10px 0;

    &:last-child {
      border: none;
    }

    td {
      padding: 10px 0;
      padding-right: 10px;
      width: 50%;
    }

    @media (min-width: 1500px) {
      .market_compare_label {
        font-size: 18px !important;
      }
    }

    .market_compare_label {
      font-size: 16px;
      color: var(--secondary-gray700);
      font-weight: 500;
      text-align: left;
    }

    .market_compare_value {
      font-size: 16px;
      color: var(--gray600);
      text-align: left;
    }
  }
}

.pl_20 {
  padding-left: 20px;
}

.brand_performance_overview_table {
  width: 100%;
  // padding: 10px 0;
  border-collapse: collapse;

  .brand_performance_overview_wrap {
    height: 300px;
    overflow: auto;

    .section_subtitle {
      font-size: 15px;
      // color: var(--gray800);
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid var(--gray200);
      color: var(--secondary-gray700);
      font-weight: 500;

      &:first-child {
        padding-top: 0px;
        border: none;
      }
    }
  }

  .brand_performance_overview_row {
    // border-bottom: 1px solid var(--gray200);
    padding: 10px 0;

    // tr {
    //   :nth-child(2n){
    //   border-bottom: 1px solid var(--gray200);
    //   }
    // }

    // &:last-child{
    //   border: none;
    // }
  }

  tr {
    width: 100%;

    td {
      width: 50%;
      padding: 0px;
    }

    .brand_performance_overview_label {
      font-size: 12px;
      color: var(--secondary-gray600);
      font-weight: 500;
      text-align: left;
      line-height: 1.3;
    }

    .brand_performance_overview_value {
      font-size: 18px;
      color: var(--gray800);
      text-align: left;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}

.text_end {
  text-align: end;
}

.market_compare_link {
  display: block;
  padding-bottom: 10px;
  cursor: pointer;
  font-size: 13px;
}

.pl_10 {
  padding-left: 10px;
}

.adjust_new_line {
  @media (max-width: 1470px) {
    .section_card_header {
      flex-direction: column;
      align-items: self-start;

      .section_card_title {
        padding-bottom: 10px;
      }
    }
  }

  @media (min-width: 1440px) {
    .title_new_line {
      white-space: unset;
      display: inline;
      width: 200px;
    }
  }

  @media screen and (width: 1600px) {
    .perfTrendLine {
      flex-direction: column;
      align-items: self-start;

      .section_card_title {
        padding-bottom: 10px;
      }
    }
  }

  .section_title_overflow {
    display: inline !important;
    width: 100% !important;
    white-space: break-spaces !important;
  }
}

.text_adjust_wrap {
  white-space: unset !important;

  .text_adjust {
    display: inline-block;
    word-wrap: break-word;
    white-space: unset;
    line-height: 1.4;
  }
}

.chart_details_wrapper .main_wrapper .pt_npt_wrapper {
  .row .value_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .value_text {
      font-size: 12px;
      font-weight: 400;
      color: #7d9eb5;
      line-height: 1.4;
      text-align: right;
    }
  }
}

.fs_12 {
  width: 12px;
}

.w_200 {
  width: 220px !important;
}

.section_sub_title {
  padding: 0 20px;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 700;
}

.w_auto {
  width: auto !important;
}

.column_heading_baseline {
  justify-content: baseline;
  padding-bottom: 0 !important;
}

#monthlytv .section_card_title {
  width: unset;
  white-space: break-spaces;
  padding-right: 10px;
}

#monthlytv .section_card_header>.section_info {
  width: 65%;
}

#monthlytv .section_card_header>.section_card_title {
  width: 65%;
}

#monthlytv .filters_wrapper {
  width: 35%;
}

td.number_align {
  text-align: end !important;
}

tfoot {
  box-shadow: 0 -1px 0 var(--primary-hover200);
  border-bottom: 1px solid var(--primary-hover200);
  background: var(--secondary-gray400);
}

tfoot td {
  font-weight: bold;
  padding: 6px 20px !important;
}

.camp_admin_dd {
  .css-3w2yfm-ValueContainer {
    flex-wrap: wrap !important;
  }
}

.ml_20 {
  margin-left: 20px;
}

.popup_wrapper .popup_box {
  // height: calc(100% - 50px);
  // overflow: auto;

  height: calc(100% - 80px);
  overflow: auto;
  padding-bottom: 20px;
}

.popup_wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 99999;
  max-height: 100%;
  overflow: hidden;
}

.popup_body {
  padding: 20px;
  margin-top: 0;
  position: relative;
  background: #fff;
  /* height: 100%; */
  /* max-height: 100%; */
  /* overflow: auto; */
  overflow: hidden;
  margin-bottom: -100px;
}

.section_card_chart_wrapper.unset_height {
  min-height: 350px;
  height: 350px;
  padding: 20px;
}

body.modal_open {
  overflow: hidden;
}

.close_popup {
  width: 40px;
  display: flex;
  height: 40px;
  font-size: 30px;
  justify-content: center;
  line-height: 0px;
  text-align: center;
  margin-right: 20px;
  align-items: center;
}

.modal_open .dashboard_navbar {
  padding-right: 5px;
}

.icon_btn {
  display: flex;
  border: 1px solid !important;
  border-color: var(--gray50) !important;
  color: var(--gray50) !important;
  padding: 5px !important;
  margin-right: 0px;

  height: 32px;
  font-size: 24px;
  width: 32px;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 20px !important;
    height: 20px;

    path {
      stroke: var(--gray50) !important;
    }
  }

  &:hover {
    border-color: var(--primary500) !important;
    color: var(--primary500) !important;

    svg {
      path {
        stroke: var(--primary500) !important;
      }
    }
  }
}

.market_compare_value {
  position: relative;
}

.unformatted_number {
  display: none;
}

.formatted_number:hover+.unformatted_number {
  display: block;
  position: absolute;
  top: 28px;
  /*   right: 0px; */
  background-color: var(--secondary-navy-blue);
  border-radius: 7px;
  border: none;
  color: var(--secondary-gray500);
  font-size: 12px;
  font-weight: var(--font-weight400);
  padding: 10px;
  max-width: 150px;
  text-align: center;
  line-height: 1.4;
  z-index: 999;
}

.chart_details_wrapper .main_wrapper .footer .value_wrapper {
  position: relative;
}

.icon_btn {
  display: flex;
  border: 1px solid !important;
  border-color: var(--gray50) !important;
  color: var(--gray50) !important;
  padding: 5px !important;
  margin-right: 0px;
  margin-left: 15px;
  height: 32px;
  font-size: 24px;
  width: 32px;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 20px !important;
    height: 20px;

    path {
      stroke: var(--gray50) !important;
    }
  }

  &:hover {
    border-color: var(--primary500) !important;
    color: var(--primary500) !important;

    svg {
      path {
        stroke: var(--primary500) !important;
      }
    }
  }
}

.tiny-tabs .tab {
  width: auto !important;
}

.tiny-tabs .primary_navigation {
  border-bottom: none !important;
  float: right;
}

.tiny-tabs .tab_item {
  padding: 8px 10px !important;
  font-size: 14px !important;
  border-radius: 4px;
  margin-bottom: 0 !important;
}

.tiny-tabs .tab .primary_navigation .tab_item.active {
  color: #11a1fd;
  color: #fff;
  border-color: #11a1fd;
  border-color: var(--primary500);
  background: var(--primary500);
  font-size: 10px;
}

.filter-searchbar-input .secondary_btn {
  width: 70px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.filter-searchbar-input .form_control {
  border-top-right-radius: 0px;
  width: 100%;
  border-bottom-right-radius: 0px;
}

.filter-searchbar-input .filter-searchbar-clear-normal {
  font-size: 14px;
  color: #111;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 10px;
  white-space: nowrap;
}

.bg_dark_blue {
  background-color: var(--secondary-gray700);
}

.badge_unset:hover+.badge_hover {
  position: static;
  padding: 5px 8px;
  font-size: 11px;
  max-width: fit-content;
  white-space: break-spaces;
  word-break: break-all;
}

.badge_unset {
  margin-right: 10px;
}

.hover_badge_unset {
  display: flex;
  align-items: center;
}

.bottomRow_divider {
  // border-top: 2px solid #aaa;
  background-color: #f6f6f6 !important;
}

.totalRow_number {
  font-weight: 600 !important;
}

.filters_yt_page {
  position: relative;
  width: 320px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: end;

  .filter {
    width: 100%;

    input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .secondary_btn {
    padding: 0px;
    width: 60px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    font-size: 13px;
  }

  .clear-button {
    font-size: 30px;
    color: #111;
    margin: 0px !important;
    cursor: pointer;
    margin-right: 10px;
    background: transparent;
    border: none;
    position: absolute;
    right: 58px;
    padding: 0px;
    color: var(--primary500);
  }
}

.value_perc_sign {
  color: #486f88;
  font-weight: 500;
}

.form_backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0.6;
  background: #fff;
  justify-content: center;
  align-items: center;

  .spinner-loader {
    width: 48px;
    height: 48px;
    border: 5px solid #666;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.impr_count_reg_title {
  white-space: unset !important;
  width: unset !important;
}

.tv_modal_wrapper {
  position: fixed;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .tv_modal {
    width: 500px;
    margin-left: 60px;
    background: #FCF4EB;
    border-radius: 7px;
    border: 1px solid #FFBB75;

    .tv_modal_content {
      height: inherit;
    }

    .tv_modal_header {
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      padding-top: 15px;

      h5 {
        font-weight: bold;
        font-size: 18px;
      }
    }

    .tv_modal_body {
      padding: 15px;
      padding-top: 0px;
      display: flex;
      align-items: center;
      column-gap: 20px;

      .tv_modal_icon svg {
        height: 70px;
      }

      .tv_modal_text {
        line-height: 1.5;

        h6 {
          font-size: 17px;
          font-weight: 500;
          color: #000;
        }

        p {
          margin: 0px;
          font-size: 15px;
          color: #777;
        }
      }

    }

    .tv_modal_footer {
      display: flex;
      justify-content: center;
      padding: 15px;

      button {
        width: 130px;
      }
    }
  }
}

.dashboard_sidebar {
  .detailed_sidebar .opened {
    .tv_modal_wrapper .tv_modal {
      margin-left: 340px;
    }
  }
}

.sticky_header_wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  color: var(--secondary-navy-blue);
  font-size: 14px;
  position: sticky;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;

  .sticky_header_content {
    border: 1px solid #FFBB75;
    background-color: #FCF4EB;
    width: 100%;
    border-radius: 6px;
    padding: 5px;
  }

  .sticky_header_body {
    display: flex;
    align-items: center;

    span {
      color: #777;
    }

    svg {
      width: auto;
      margin-right: 7px;
    }

  }
}

.bg_transparent_icon_btn {
  background-color: transparent;
  border: none;
  outline: unset;
  font-size: 38px;
  color: #B6B6B6;
  cursor: pointer;
  height: 10px;
  line-height: 0px;
  font-size: 28px;
}

.main_content_wrapper {
  height: inherit;

  &.show {
    overflow: auto;
    height: calc(100vh - 120px);

    .left_drawer.col_span_1.fixed-left-grid {
      top: 0px
    }
  }
}

#content_wrapper {
  &.show {
    .content_section {
      overflow: auto;
      height: calc(100vh - 120px);
    }
  }
}

.mr_5 {
  margin-right: 5px;
}


.filters_wrapper {
  .column_filter {
    width: 100px !important;
  }

  .table-toggle-filter {
    position: relative;

    .checkbox-list {
      // max-width: 200px !important;
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      position: absolute;
      right: 0;
      // width: 100%;

      z-index: 100000;
      background: white;
      top: 45px;
      width: 190px;


      .checkbox_selectall_checkbox {
        display: flex;
        justify-content: start;
        align-items: center;

        input {
          flex: unset;
          height: 15px;
          width: 15px;
        }

        label {
          font-size: 14px;
          color: #777
        }
      }

      .checkbox_search_wrap {

        .checbox_search_clear {
          position: absolute;
          background: white;
          color: var(--primary700);
          cursor: pointer;
          right: 11px;
          border: 0px;
          top: 11px;
          height: 26px;
          font-size: 23px;
          width: 22px;
          display: flex;
          align-items: center;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      .checkbox_list_search {
        padding: 10px;

        input {
          padding: 0 10px;
          height: 28px;
          position: relative;
        }

        .checkbox_select_all {
          font-size: 12px;
          background: transparent;
          outline: 0;
          border: 0;
          text-decoration: underline;
          color: var(--primary700);
          text-align: end;
          cursor: pointer;
        }
      }

      .checkbox-list-items {
        height: 150px;
        overflow: auto;
      }

      .checkbox-list-wrap {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 8px;

        input[type='checkbox'] {
          width: 15px;
          height: 15px;
          flex: unset;
          cursor: pointer;
        }

        label {
          font-size: 14px;
          padding: 4px 8px;
          margin: 0px !important;
          line-height: 1.3;
          cursor: pointer;
        }

      }

      .checkbox_button_wrap {
        display: flex;
        gap: 10px;
        padding: 10px;
      }
    }
  }
}

.checktable-input-box {
  flex: unset;
  width: min-content;
  scale: 1.2;
}

.highlight {
  background-color: yellow;
}

.select_page_number {
  padding: 6px 10px;
  border: 1px solid #11a1fd;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  color: #11a1fd;


  &:focus-visible {
    outline: unset;
  }
}

.filter-searchbar-input {
  justify-content: flex-end;
  position: relative;

  .filter-searchbar-clear {
    color: var(--primary500);
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    text-decoration: none;
    background-color: transparent;
    outline: unset;
    border: none;
    right: 60px;
  }

  .form_control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: auto;
    max-width: 250px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .btn-medium {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 14px;
    padding: 0 10px;
    height: 40px;
    text-transform: capitalize;
  }
}